import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import "./css/common.css";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Button } from "reactstrap";
import TagManager from "react-gtm-module";

import {
  StatusLine,
  Preloader,
  API,
  UPLOADS,
  loadUserData,
  LocationSelect,
  testNumber,
  ShareFb,
} from "./common.js";
import { cities } from "./ukraine.js";
import { Events, EventEdit } from "./events";
import { Test } from "./test";
import img_logo0 from "./img/logo_blue.png";
import img_profileButton from "./img/profile.png";
import img_telegram from "./img/icon_telegram.png";
import img_viber from "./img/icon_viber.png";
import img_phone from "./img/icon_phone.png";
import img_fb2 from "./img/icon_fb2.png";
import img_rider from "./img/rider_small.jpg";
import img_noAvatar from "./img/profile.png";

import { FaSignInAlt, FaHome, FaUserPlus } from "react-icons/fa";
import { RiLoginCircleLine, RiLogoutCircleLine } from "react-icons/ri";
import { BsFillTrashFill } from "react-icons/bs";

import img_slide1 from "./img/slide1.jpg";
import img_slide2 from "./img/slide2.jpg";
import img_slide4 from "./img/slide4.jpg";
import img_slide7 from "./img/slide7.jpg";
import img_slide8 from "./img/slide8.jpg";

const tagManagerArgs = {
  // google analitycs
  gtmId: "G-V5E79EZDGC",
};
TagManager.initialize(tagManagerArgs);

const Header = (props) => {
  return (
    <header>
      <div className="header-content">
        <MainLogo />
        <nav>
          {/* <ShareFb url="n4r.com.ua" text="Поширити" /> */}
          <Navigation />
          <Authorization user={props.user} />
        </nav>
      </div>
    </header>
  );
};

const MainLogo = () => {
  return (
    <div className="main-logo">
      <img src={img_logo0} alt="" />
    </div>
  );
};

const Navigation = () => {
  // navigation buttons block
  const NavItem = (props) => {
    //navigation button item
    return (
      <div className="nav-item">
        <a href={props.href}>{props.title}</a>
      </div>
    );
  };
  return (
    <div className="buttons">
      <NavItem
        href={"/ride/"}
        title={"Покатеньки"}
        alt={"велосипедні покатушки/покатеньки"}
      />
      <NavItem href={"/hike/"} title={"Походи"} alt={"велосипедні походи"} />
    </div>
  );
};

const Authorization = (props) => {
  // autorization, registration, profile & logout components
  const Login = (props) => {
    // user is login
    const buttonLogoutClick = () => {
      // logout click handler
      let data = new FormData();
      data.append("session", localStorage.getItem("session"));
      axios // log out global
        .post(API + "logout", data)
        .then((resp) => {
          localStorage.removeItem("session"); // log out local
          setTimeout(() => {
            window.location.assign("/");
          }, 500);
        })
        .catch((err) => {
          console.log("Log out error!", err);
        });
    };
    const src = props.user.img
      ? API + UPLOADS + props.user.img
      : img_profileButton;
    const name = props.user.name ? props.user.name : props.user.email;
    return (
      <div className="user-login">
        <div className="avatar">
          <a href="/profile" title="Профіль">
            <img src={src} alt="" />
          </a>
        </div>
        <span className="name">{name}</span>
        <div className="button" onClick={buttonLogoutClick} title="Вийти">
          <RiLogoutCircleLine />
        </div>
      </div>
    );
  };

  const Logout = (props) => {
    // user is not login
    return (
      <div className="user-login">
        <div className="button">
          <a href="/login" title="Авторизація">
            <RiLoginCircleLine />
          </a>
        </div>
      </div>
    );
  };

  return props.user.login ? <Login user={props.user} /> : <Logout />;
};

const Slide = (props) => {
  return (
    <div className={"slide " + props.position}>
      <div className="slide-img">
        <img src={props.img} alt="Велопокатеньки та походи" />
      </div>
      <div className="slide-text">{props.text}</div>
    </div>
  );
};

const Intro = () => {
  return (
    <div className="intro">
      <h1>Need for a ride -</h1>
      <div>
        <span className="text">
          сервіс спільних велосипедних поїздок. Ми створили його для того, щоб
          шанувальники велосипедних мандрівок могли зручно та комфортно
          знаходити один одного. Ви можете створювати велосипедні покатеньки чи
          походи, запрошувати до них інших. Знаходити цікаві поїздки та брати в
          них участь. Рушайте до нових місць разом з &laquo;Need for a
          ride&raquo;!
        </span>
        <span className="img">
          <img src={img_rider} alt="сервіс спільних велосипедних поїздок" />
        </span>
      </div>
    </div>
  );
};
const Footer = () => {
  return (
    <div className="footer">
      Український велосипедний клуб <div>&laquo;Need for a ride&raquo;</div>
      <a
        href="https://www.facebook.com/Need4ride-100504085513192"
        target="blanc"
        rel="nofollow"
      >
        <img src={img_fb2} alt="" />
      </a>
    </div>
  );
};
const UserLogin = (props) => {
  // user login page
  const [preloaderStatus, setPreloaderStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState("");
  const [buttonsStatus, setButtonsStatus] = useState(true);
  const [formEmail, setFormEmail] = useState("");
  const [formPass, setFormPass] = useState("");
  const ButtonsBar = (props) => {
    return (
      <div style={{ display: props.visible ? "block" : "none" }}>
        <Button color="primary" onClick={submit}>
          Увійти{"  "}
          <span>
            <FaSignInAlt />
          </span>
        </Button>
        <a href="/register">
          <Button color="secondary">
            Зареєструватись{"  "}
            <span>
              <FaUserPlus />
            </span>
          </Button>
        </a>
        <a href="/forgot">
          <Button color="secondary">Забули пароль</Button>
        </a>
        <a href="/">
          <Button color="secondary">
            На головну {"  "}
            <FaHome />
          </Button>
        </a>
      </div>
    );
  };

  const submit = () => {
    const getErrorMessage = (errCode) => {
      // analyse api returned error code & returns error message
      switch (errCode) {
        case 0:
          return false;
        case 1:
          return "Невірний email, повторіть спробу";
        case 2:
          return "Невірний пароль";
        case 3:
          return "Помилка вторизації! Повторіть спробу";
        default:
          return false;
      }
    };
    setButtonsStatus(false);
    setPreloaderStatus(true);
    setTimeout(() => {
      const data = new FormData();
      data.append("email", formEmail);
      data.append("pass", formPass);
      axios
        .post(API + "/login", data)
        .then((resp) => {
          let err = getErrorMessage(resp.data.error);
          if (err) {
            setPreloaderStatus(false);
            setButtonsStatus(true);
            setStatusType("error");
            setStatusMessage(err);
          } else {
            localStorage.setItem("session", resp.data.id);
            setStatusType("ok");
            setStatusMessage(
              `Вітаю, ${resp.data.name}! Ви успішно авторизувались. Let's ride!`
            );
            setTimeout(() => {
              window.location.assign("/");
            }, 2000);
          }
        })
        .catch((err) => {
          console.log("Err: ", err);
        });
    }, 1000);
  };
  return (
    <div className="user-tools">
      <h3 className="form-header">Вхід</h3>
      <form className="form-body">
        <input
          type="email"
          value={formEmail}
          placeholder={"Email:"}
          onChange={(e) => {
            setFormEmail(e.target.value);
          }}
        />
        <input
          type="password"
          value={formPass}
          placeholder={"Password"}
          onChange={(e) => {
            setFormPass(e.target.value);
          }}
        />
      </form>
      <div className="form-footer">
        <ButtonsBar visible={buttonsStatus} />
        <Preloader visible={preloaderStatus} />
        <StatusLine message={statusMessage} status={statusType} />
      </div>
    </div>
  );
};

const UserRegister = (props) => {
  // user register page
  const [preloaderStatus, setPreloaderStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState("");
  const [buttonsStatus, setButtonsStatus] = useState(true);
  const [formEmail, setFormEmail] = useState("");
  const [loginButtonEnable, setLoginButtonEnable] = useState(false);
  const LoginButton = (props) => {
    if (!props.enable) return null;
    return (
      <div>
        <a href="/login">
          <Button type="button" color="primary">
            Увійти{"  "}
            <FaSignInAlt />
          </Button>
        </a>
        <a href="/">
          <Button type="button" color="secondary">
            На головну{"  "}
            <FaHome />
          </Button>
        </a>
      </div>
    );
  };
  const ButtonsBar = (props) => {
    return (
      <div style={{ display: props.visible ? "block" : "none" }}>
        <Button type="button" color="primary" onClick={submit} active>
          Зареєструватись
        </Button>
        <a href="/">
          <Button type="button" color="secondary">
            На головну
          </Button>
        </a>
      </div>
    );
  };
  const submit = () => {
    const data = new FormData();
    data.append("email", formEmail);
    setPreloaderStatus(true);
    setButtonsStatus(false);
    setTimeout(() => {
      axios
        .post(API + "registration", data)
        .then((resp) => {
          setPreloaderStatus(false);
          if (resp.data.error === 0) {
            setStatusType("ok");
            setStatusMessage(
              "Вітаю! Ви успішно зареєструвались. На зазначений email надіслано пароль для авторизації. Якщо ви не отримали листа, перевірте папку спам або скористайтесь функцією 'забули пароль' для повторного надсилання паролю. Go ride!"
            );
            setLoginButtonEnable(true);
            return;
          }
          setStatusType("error");
          setButtonsStatus(true);
          switch (resp.data.error) {
            case 1:
              setStatusMessage("Некоректний email");
              break;
            case 2:
              setStatusMessage(
                "Такий email вже зареєстрований! Введіть вірний пароль або скористайтесь відновленням паролю"
              );
              break;
            case 3:
              setStatusMessage("Помилка реєстрації, спробуйте знову.");
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  };
  return (
    <div className="user-tools">
      <h3 className="form-header">Реєстрація</h3>
      <form className="form-body">
        <input
          type="email"
          placeholder="Email"
          value={formEmail}
          onChange={(e) => {
            setFormEmail(e.target.value);
          }}
        />
      </form>
      <div className="form-footer">
        <ButtonsBar visible={buttonsStatus} />
        <Preloader visible={preloaderStatus} />
        <StatusLine message={statusMessage} status={statusType} />
        <LoginButton enable={loginButtonEnable} />
      </div>
    </div>
  );
};

const ForgotPassword = () => {
  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [checkCode, setCheckCode] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true);
  const [preloaderStatus, setPreloaderStatus] = useState(false);
  const [emailVisible, setEmailVisible] = useState(true);

  const ButtonsBar = (props) => {
    if (!props.visible) return null;
    return (
      <div style={{ display: props.visible ? "block" : "none" }}>
        <Button type="button" color="primary" onClick={submit}>
          Надіслати
        </Button>
        <a href="/">
          <Button type="button" color="secondary">
            На головну
          </Button>
        </a>
      </div>
    );
  };

  const submit = () => {
    const data = new FormData();
    data.append("email", formEmail);
    setButtonStatus(false);
    setPreloaderStatus(true);
    setStatusType("");
    setStatusMessage("Надсилання...");
    setTimeout(() => {
      axios
        .post(API + "forgot", data)
        .then((resp) => {
          setPreloaderStatus(false);
          if (resp.data.error === 0) {
            setStatusMessage("");
            setEmailVisible(false);
            return;
          }
          setPreloaderStatus(false);
          setButtonStatus(true);
          setStatusType("error");
          switch (resp.data.error) {
            case 1:
              setStatusMessage("Такий email не зареєстрований");
              break;
            case 2:
              setStatusMessage("Помилка, спробуйте знову.");
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  };

  const changePass = (code) => {
    let data = new FormData();
    data.append("email", formEmail);
    data.append("code", code);
    console.log(code);
    setPreloaderStatus(true);
    setStatusType("");
    setStatusMessage("Перевірка...");
    setTimeout(() => {
      axios
        .post(API + "new_password", data)
        .then((resp) => {
          // setPreloaderStatus(false);
          if (resp.data.error === 0) {
            setStatusMessage(
              "Новий пароль надіслано на вашу електрону адресу. Перевірте пошту. Якщо лист не надійшов, перевірте папку спам або повторіть спробу"
            );
            setTimeout(() => {
              window.location.assign("/");
            }, 4000);
            return;
          }
          setPreloaderStatus(false);
          setStatusType("error");
          switch (resp.data.error) {
            case 1:
              setStatusMessage("Невірний код!");
              break;
            case 2:
              setStatusMessage("Помилка, спробуйте знову.");
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 500);
  };

  if (emailVisible) {
    return (
      <div className="user-tools">
        <h3 className="form-header">Відновлення паролю</h3>
        <form className="form-body">
          <input
            type="email"
            placeholder="Email"
            value={formEmail}
            onChange={(e) => {
              setFormEmail(e.target.value);
            }}
          />
          <div>На зазначений email буде надісланий перевірочний код</div>
          <div className="separator"></div>
        </form>
        <div className="form-footer">
          <StatusLine message={statusMessage} status={statusType} />
          <ButtonsBar visible={buttonStatus} />
          <Preloader visible={preloaderStatus} />
        </div>
      </div>
    );
  } else
    return (
      <div className="user-tools">
        <h3 className="form-header">Підтвердження відновлення паролю</h3>
        <form className="form-body">
          <input
            className="small"
            type="text"
            placeholder="Введіть перевірочний код"
            value={checkCode}
            onChange={(e) => {
              if (testNumber(e.target.value, 999999)) {
                setCheckCode(e.target.value);
                if (e.target.value.length === 6) changePass(e.target.value);
              }
            }}
          />
          <div>
            На ваш email було надіслано 6-значний перевірочний код. Якщо код не
            надійшов, перевірте папку спам або повторіть спробу
          </div>
          <div className="separator"></div>
          <a href="/forgot">
            <Button type="button" color="primary">
              Надіслати знову
            </Button>
          </a>
          <a href="/">
            <Button type="button" color="secondary">
              На головну
            </Button>
          </a>
        </form>
        <div className="form-footer">
          <StatusLine message={statusMessage} />
          <Preloader visible={preloaderStatus} />
        </div>
      </div>
    );
};

//=====================================================================================================================

const UserImgUpload = (props) => {
  // user avatar upload component
  // props:
  // img - img file
  const [status, setStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const AvatarImg = (props) => {
    if (!props.img) return null;
    return (
      <div className="avatar-upload-img">
        <div className="avatar">
          <div>
            <img src={props.img} alt="need for a ride велосипедист райдер" />
          </div>
        </div>
        <div className="delete">
          <BsFillTrashFill
            onClick={() => {
              props.setImage("");
              setStatusMessage("");
            }}
          />
        </div>
      </div>
    );
  };

  const AvatarUploadBtn = (props) => {
    if (props.img) return null;
    return (
      <div className="avatar-upload-btn">
        <label htmlFor="img_file_download">
          <div>
            <div>Завантажте зображення</div>
            <span className="small">(не більше 300 Кбайт)</span>
          </div>
          <div className="img">
            <img src={img_noAvatar} alt="need for a ride велосипедист" />
          </div>
        </label>
        <input
          id="img_file_download"
          accept=".jpg,.png,.jpeg"
          type="file"
          onChange={(e) => {
            const data = new FormData();
            data.append("file", e.target.files[0]);
            setStatus(true);
            axios
              .post(API + "registration/load_file", data)
              .then((resp) => {
                if (resp.data.errors) {
                  console.log(resp.data.errors);
                  setStatusMessage(
                    "Зображення мусить бути не більше 300 Кбайт!"
                  );
                  setTimeout(() => {
                    // setStatusMessage("");
                    setStatus(false);
                  }, 500);
                } else {
                  // complete image download
                  setStatus(true);
                  props.setImage(resp.data.file);
                  setStatusMessage("");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="user-img-upload">
        <AvatarImg img={props.img} setImage={props.setImage} />
        <AvatarUploadBtn img={props.img} setImage={props.setImage} />
      </div>
      <Preloader visible={status} />
      <StatusLine message={statusMessage} status={"warning"} />
    </div>
  );
};

const UserProfile = (props) => {
  const [preloaderStatus, setPreloaderStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true);
  const [name, setName] = useState("");
  const [img, setImg] = useState(null);
  const [city, setCityState] = useState(null);
  const [about, setAbout] = useState("");
  const [user, setUserState] = useState({ id: null, login: false });
  const [contact, setContact] = useState("");
  const [contactType, setContactType] = useState(1);
  const [changePassVisible, setChangePassVisible] = useState(false);

  const setUser = (userData) => {
    if (!userData.login) return;
    setUserState({ id: userData.id, login: true });
    setName(userData.name);
    setImg(userData.img);
    setCityState(userData.city);
    setAbout(userData.about);
    setContact(userData.contact);
    setContactType(+userData.contactType);
  };
  useEffect(() => {
    loadUserData(setUser);
  }, []);
  if (!user.login)
    return (
      // exit if user is not login
      <div className="warning">
        <h3>Вам необхідно авторизуватись!</h3>
        <a href="/login">
          <Button color="primary">Авторизація</Button>
        </a>
      </div>
    );
  const setCity = (city) => {
    setCityState(city);
  };
  const setImage = (file) => {
    setImg(file);
  };
  const setContactValue = (val) => {
    if (val.length <= 30) setContact(val);
  };
  const ProfileImg = (props) => {
    const src = props.img
      ? API + UPLOADS + props.img // image uploaded
      : null; // image not loaded
    return <UserImgUpload img={src} setImage={props.setImage} />;
  };
  //--------------------------------------------------
  const UserContacts = (props) => {
    const imgArr = [img_phone, img_viber, img_telegram];
    let imgList = [];
    let css;
    imgArr.forEach((i, n) => {
      css = n + 1 === +props.contactType ? "active" : "null";
      imgList.push(
        <img
          className={css}
          src={i}
          data-value={n + 1}
          alt=""
          key={n}
          onClick={(e) => {
            props.setContactType(+e.target.dataset.value);
          }}
        />
      );
    });
    return <div>{imgList}</div>;
  };
  UserContacts.propTypes = {
    setContactType: PropTypes.func,
    setContact: PropTypes.func,
    contactType: PropTypes.number,
  };

  //--------------------------------------------------
  const ButtonsBar = (props) => {
    const ChangePassButton = (props) => {
      if (!props.visible) return null;
      return (
        <Button color="secondary" onClick={() => setChangePassVisible(true)}>
          Змінити пароль
        </Button>
      );
    };
    return (
      <div style={{ display: props.visible ? "block" : "none" }}>
        <Button color="primary" onClick={props.submit}>
          Зберегти
        </Button>
        <a href="/">
          <Button color="secondary">Повернутись</Button>
        </a>
        <ChangePassButton visible={!changePassVisible} />
      </div>
    );
  };
  // ----------------------------------------------------------------
  const ChangePassword = (props) => {
    const [oldPass, setOldPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [newPass2, setNewPass2] = useState("");
    const submit = async () => {
      let data = new FormData();
      data.append("pass", oldPass);
      data.append("new", newPass);
      data.append("confirm", newPass2);
      data.append("session", localStorage.getItem("session"));
      setPreloaderStatus(true);
      let res = await axios.post(API + "change_password", data);
      const err = res.data.err;
      setTimeout(() => {
        setPreloaderStatus(false);
        setStatusType("error");
        switch (err) {
          case 1:
            setStatusMessage("Помилка авторизації!");
            setTimeout(() => window.location.assign("/"), 2000);
            break;
          case 2:
            setStatusMessage("Не збігаються паролі!");
            break;
          case 3:
            setStatusMessage("Невірний поточний пароль!");
            break;
          case 4:
            setStatusMessage(
              "Новий пароль мусить містити не менше 6 символів!"
            );
            break;
          case 0:
            setStatusType("ok");
            setStatusMessage("Пароль успішно змінено");
            props.setChangePassVisible(false);
            setTimeout(() => setStatusMessage(""), 2500);
            break;
          default:
            break;
        }
      }, 1000);
      console.log(err);
    };
    if (!props.visible) return null;
    return (
      <div className="change-password-component">
        <input
          type="password"
          placeholder="Поточний пароль"
          val={oldPass}
          onChange={(e) => setOldPass(e.target.value)}
        />
        <input
          type="password"
          placeholder="Новий пароль (не менше 6 символів)"
          val={newPass}
          onChange={(e) => setNewPass(e.target.value)}
        />
        <input
          type="password"
          placeholder="Підтвердіть новий пароль"
          val={newPass2}
          onChange={(e) => setNewPass2(e.target.value)}
        />
        <Button color="primary" onClick={() => submit()}>
          Підтвердити зміну паролю
        </Button>
      </div>
    );
  };
  // ----------------------------------------------------------------
  const submit = () => {
    setPreloaderStatus(true);
    setButtonStatus(false);
    const data = new FormData();
    data.append("session", localStorage.getItem("session"));
    data.append("name", name);
    data.append("city", city);
    data.append("img", img);
    data.append("about", about);
    data.append("contacts", contact);
    data.append("contacts_type", contactType);
    setTimeout(() => {
      axios
        .post(API + "profile_save", data)
        .then((resp) => {
          if (resp.data.error === 0) {
            setStatusType("ok");
            setStatusMessage("Профіль збережено.");
            setTimeout(() => {
              window.location.assign("/");
            }, 2000);
            return;
          }
          if (resp.data.error === 1) {
            setStatusType("warning");
            setStatusMessage("Необхідно авторизуватись!");
            setTimeout(() => {
              window.location.assign("/");
            }, 1500);
          }
          setButtonStatus(true);
          setPreloaderStatus(false);
          switch (resp.data.error) {
            case 2:
              setStatusType("warning");
              setStatusMessage("Введіть ім'я користувача від 2 літер!");
              break;
            case 3:
              setStatusType("error");
              setStatusMessage("Таке ім'я вже використовує інший користувач!");
              break;
            case 4:
              setStatusType("warning");
              setStatusMessage("Оберіть ваше місто!");
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 300);
  };
  //-----------------------------------------------
  if (!user || !user.id) return null;
  if (!city) return null;
  return (
    <div className="user-tools">
      <h3 className="form-header">Профіль користувача</h3>
      <form className="form-body">
        <input
          type="text"
          placeholder="Ваше ім'я (нікнейм)"
          value={name}
          onChange={(e) => {
            if (e.target.value.length <= 20) setName(e.target.value);
          }}
        />
        <ProfileImg img={img} setImage={setImage} />
        <LocationSelect city={city} setCity={setCity} ukraine={cities} />
        <li className="user-contacts-component">
          <UserContacts
            contactType={contactType}
            setContactType={setContactType}
          />
          <input
            className="small"
            type="text"
            value={contact}
            onChange={(e) => setContactValue(e.target.value)}
            placeholder={"Ваші контакти"}
          />
        </li>
        <textarea
          placeholder="Про себе..."
          value={about}
          onChange={(e) => {
            setAbout(e.target.value);
          }}
        ></textarea>
        <ChangePassword
          visible={changePassVisible}
          setChangePassVisible={setChangePassVisible}
        />
      </form>
      <div className="form-footer">
        <ButtonsBar visible={buttonStatus} submit={submit} />
        <Preloader visible={preloaderStatus} />
        <StatusLine message={statusMessage} status={statusType} />
      </div>
    </div>
  );
};

//==================================================================================================

const Hike = (props) => {
  // Hike page component
  return <Events type={"hike"} user={props.user} />;
};

const Ride = (props) => {
  // Ride page component
  return <Events type={"ride"} user={props.user} />;
};

const Main = (props) => {
  return (
    <div className="main-page">
      <Header user={props.user} />
      <Intro />
      <Slide
        img={img_slide1}
        text="Створюйте нові покатеньки та запрошуйте друзів"
      />
      <Slide
        position="top left"
        img={img_slide2}
        text="Обирайте цікаві покатеньки та долучайтесь до них"
      />
      <Slide
        position="left"
        img={img_slide7}
        text="Знаходьте однодумців та подорожуйте разом"
      />
      <Slide
        position="top right"
        img={img_slide4}
        text="Пізнавайте світ на своєму велосипеді "
      />
      <Slide
        position="left"
        img={img_slide8}
        text="Рушайте до нових пригод разом з &laquo;Need for a ride&raquo; "
      />
      <Footer />
    </div>
  );
};

const Articles = (props) => {
  // article page component
  return (
    <div>
      <h1>Articles</h1>
    </div>
  );
};

const Catalog = (props) => {
  // ride & hike pages component
  return (
    <div>
      <h1>Catalog</h1>
    </div>
  );
};

const App = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    // load current user data
    loadUserData(setUser);
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main user={user} />} />
          <Route path="/login" element={<UserLogin />} />
          <Route path="/register" element={<UserRegister />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/ride" element={<Ride user={user} />} />
          <Route path="/hike" element={<Hike user={user} />} />
          <Route path="/event/:id" element={<EventEdit />} />
          <Route path="/articles" element={Articles} />
          <Route path="/catalog" element={Catalog} />
          <Route path="/test" element={Test} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
