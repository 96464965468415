import React, { useState } from "react";
import axios from "axios";

import {
  getDaysWord,
  eventStatusArr,
  API,
  UPLOADS,
  EventProperties,
} from "./common";
import { Link } from "react-router-dom";
import { BsBuilding, BsFillPeopleFill } from "react-icons/bs";
import { GiCampingTent, GiCancel } from "react-icons/gi";
import { IoLockClosed } from "react-icons/io5";
// import { RiEditCircleFill } from "react-icons/ri";
import { AiTwotoneLike } from "react-icons/ai";
import { ImEye } from "react-icons/im";
import { FaRegCommentDots, FaCommentDots } from "react-icons/fa";
import img_noAvatar from "./img/profile.png";
import img_group_noAvatar from "./img/group.png";

// this module contains components for display the events-list page (rides & hikes list)

const Event = (props) => {
  // events list one item component
  const statusClass = +props.item.status > 1 ? " cancelled" : "";
  return (
    <div
      className={"event-item" + statusClass}
      onClick={(e) => {
        localStorage.setItem("ref", e.currentTarget.getAttribute("id"));
      }}
      id={props.item.id}
      key={`key${props.item.id}`}
    >
      <Link to={`/event/${props.item.id}`} key={`key${props.item.id}`}>
        <div className="event-item-header" key={props.item.id}>
          <div className="event-item-header-description" key={props.item.id}>
            <EventLocationDate
              cityID={props.item.city_id}
              city={props.city}
              date={props.item.date}
            />

            <EventDescription
              description={props.item.event}
              author={props.item.author_id}
              distance={props.item.distance}
            />
            <EventHikeType
              duration={props.item.duration}
              hikeType={props.item.hike_type}
            />
          </div>
          <div className="event-item-header-author">
            <UserComponent
              userImg={props.item.author_img}
              groupImg={props.item.group_img}
              user={props.item.author_name}
              group={props.item.group_name}
            />
          </div>
        </div>
        <EventProperties item={props.item} />
        <div className="event-item-footer">
          <div className="event-item-footer-status">
            <EventStatus
              status={props.item.status}
              dateChange={props.item.date_change}
            />
          </div>
          <div className="event-item-footer-like">
            <EventViews views={props.item.views} />
            <EventCount
              joined={props.item.joined}
              count={+props.item.registered_count + +props.item.add_people}
            />
            <EventComment count={props.item.comments_count} unread={false} />
            <EventLike
              like={props.item.like}
              likesCount={props.item.likes_count}
              login={props.login}
              eventId={props.item.id}
              userId={props.currentUser}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

const UserComponent = (props) => {
  const img = props.groupId ? props.groupImg : props.userImg;
  const imgTag = img ? (
    <img src={API + UPLOADS + img} alt="Фото профілю n4r" />
  ) : props.groupId ? (
    <img src={img_group_noAvatar} alt="Фото профілю n4r" />
  ) : (
    <img src={img_noAvatar} alt="Фото профілю n4r" />
  );
  return (
    <div className="user-component">
      <div className="img">{imgTag}</div>
      <div className="name">{props.user}</div>
    </div>
  );
};

const EventComment = (props) => {
  const icon = props.unread ? <FaCommentDots /> : <FaRegCommentDots />;
  const iconClass = props.unread ? "icon unread" : "icon";
  const count = props.count ? props.count : 0;
  return (
    <div className="item">
      <span className={iconClass}>{icon}</span>
      {count}
    </div>
  );
};

const EventCount = (props) => {
  // participients count
  const joinedClass = props.joined ? "icon registered" : "icon";
  const cnt =
    (props.count ? +props.count : 0) +
    (props.addPeople ? +props.addPeople + 1 : 1);
  return (
    <div className="item">
      <span className={joinedClass}>
        <BsFillPeopleFill />
      </span>
      {cnt}
    </div>
  );
};

const EventViews = (props) => {
  // views count
  return (
    <div className="item">
      <span className={"icon"}>
        <ImEye />
      </span>
      {props.views}
    </div>
  );
};

const EventLike = (props) => {
  // user like
  const setLikeRequest = async () => {
    try {
      // set like
      let data = new FormData();
      data.append("user_id", props.userId);
      data.append("event_id", props.eventId);
      data.append("value", +!like);
      let res = await axios.post(API + "set_like", data);
      if (res.data) {
      }
    } catch (err) {
      console.log("Likes load error: ", err);
    }
  };
  const [like, setLike] = useState(+props.like);
  const [likeCount, setLikeCount] = useState(+props.likesCount);
  const likeClass = like ? " active" : "";
  return (
    <div
      className="item"
      onClick={(e) => {
        e.preventDefault();
        if (!props.login) return;
        if (!like) setLikeCount(+likeCount ? +likeCount + 1 : 1);
        if (like) setLikeCount(+likeCount ? +likeCount - 1 : 0);
        setLike(!+like);
        setLikeRequest();
      }}
    >
      <span className={"icon like" + likeClass}>
        <AiTwotoneLike />
      </span>
      {likeCount}
    </div>
  );
};

const EventDescription = (props) => {
  return (
    <div className="event-item-name">
      {/* <EventIsAuthor author={props.author} /> */}
      <span className="distance">{props.distance} км</span>
      <span className="description">{props.description}</span>
    </div>
  );
};

const EventHikeType = (props) => {
  if (props.duration < 2) return null;
  const hikeType =
    props.hikeType === "1"
      ? "в готелях"
      : props.hikeType === "2"
      ? "в наметах"
      : "";
  const hikeTypeImg =
    props.hikeType === "1" ? (
      <span style={{ color: "white" }}>
        <BsBuilding />
      </span>
    ) : props.hikeType === "2" ? (
      <span className="camp">
        <GiCampingTent />
      </span>
    ) : null;
  return (
    <div className="event-hike-type-component">
      {props.duration} {getDaysWord(props.duration)} {hikeType} {hikeTypeImg}
    </div>
  );
};

const EventStatus = (props) => {
  let icon;
  switch (+props.status) {
    case 2:
      icon = <IoLockClosed />;
      break;
    case 3:
      icon = <GiCancel />;
      break;
    default:
      break;
  }
  const dateChange = props.dateChange ? (
    <span className="date-change">
      Відкориговано: {props.dateChange.toString()}
    </span>
  ) : null;
  const statusText =
    props.status == 1 || !props.status ? (
      dateChange
    ) : (
      <span>
        <span>{eventStatusArr[props.status]}</span>
        <span className="icon">{icon}</span>
      </span>
    );
  return <div className="event-status">{statusText}</div>;
};

const EventLocationDate = (props) => {
  return (
    <div className="event-item-location-date">
      <span className="event-item-location">{props.city}</span>
      <span className="event-item-date">{props.date}</span>
    </div>
  );
};

// const EventIsAuthor = (props) => {
//   if (!props.author) return null;
//   return (
//     <div className="event-is-author">
//       <span className="icon">
//         <RiEditCircleFill />
//       </span>
//     </div>
//   );
// };

export { Event };
