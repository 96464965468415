const cities = [
  {
    id: 36,
    parent: 0,
    region: "АЛУПКА",
  },
  {
    id: 26,
    parent: 0,
    region: "АЛУШТА",
  },
  {
    id: 32,
    parent: 0,
    region: "АРМЯНСЬК",
  },
  {
    id: 37,
    parent: 0,
    region: "БАХЧИСАРАЙ",
  },
  {
    id: 38,
    parent: 0,
    region: "БІЛОГІРСЬК",
  },
  {
    id: 27,
    parent: 0,
    region: "ДЖАНКОЙ",
  },
  {
    id: 28,
    parent: 0,
    region: "ЄВПАТОРІЯ",
  },
  {
    id: 29,
    parent: 0,
    region: "КЕРЧ",
  },
  {
    id: 39,
    parent: 0,
    region: "КІРОВСЬКЕ",
  },
  {
    id: 41,
    parent: 0,
    region: "КРАСНОГВАРДІЙСЬКЕ",
  },
  {
    id: 30,
    parent: 0,
    region: "КРАСНОПЕРЕКОПСЬК",
  },
  {
    id: 42,
    parent: 0,
    region: "ЛЕНІНЕ",
  },
  {
    id: 44,
    parent: 0,
    region: "НИЖНЬОГІРСЬКИЙ",
  },
  {
    id: 45,
    parent: 0,
    region: "ПЕРВОМАЙСЬКЕ",
  },
  {
    id: 46,
    parent: 0,
    region: "РОЗДОЛЬНЕ",
  },
  {
    id: 31,
    parent: 0,
    region: "САКИ",
  },
  {
    id: 49,
    parent: 0,
    region: "СЕВАСТОПОЛЬ",
  },
  {
    id: 25,
    parent: 0,
    region: "СІМФЕРОПОЛЬ",
  },
  {
    id: 47,
    parent: 0,
    region: "СОВЄТСЬКИЙ",
  },
  {
    id: 40,
    parent: 0,
    region: "СТАРИЙ КРИМ",
  },
  {
    id: 34,
    parent: 0,
    region: "СУДАК",
  },
  {
    id: 33,
    parent: 0,
    region: "ФЕОДОСІЯ",
  },
  {
    id: 48,
    parent: 0,
    region: "ЧОРНОМОРСЬКЕ",
  },
  {
    id: 43,
    parent: 0,
    region: "ЩОЛКІНЕ",
  },
  {
    id: 35,
    parent: 0,
    region: "ЯЛТА",
  },
  {
    id: 56,
    parent: 1,
    region: "БАР",
  },
  {
    id: 57,
    parent: 1,
    region: "БЕРШАДЬ",
  },
  {
    id: 50,
    parent: 1,
    region: "ВІННИЦЯ",
  },
  {
    id: 58,
    parent: 1,
    region: "ГАЙСИН",
  },
  {
    id: 74,
    parent: 1,
    region: "ГНІВАНЬ",
  },
  {
    id: 51,
    parent: 1,
    region: "ЖМЕРИНКА",
  },
  {
    id: 59,
    parent: 1,
    region: "ІЛЛІНЦІ",
  },
  {
    id: 60,
    parent: 1,
    region: "КАЛИНІВКА",
  },
  {
    id: 53,
    parent: 1,
    region: "КОЗЯТИН",
  },
  {
    id: 61,
    parent: 1,
    region: "КРИЖОПІЛЬ",
  },
  {
    id: 54,
    parent: 1,
    region: "ЛАДИЖИН",
  },
  {
    id: 62,
    parent: 1,
    region: "ЛИПОВЕЦЬ",
  },
  {
    id: 63,
    parent: 1,
    region: "ЛІТИН",
  },
  {
    id: 52,
    parent: 1,
    region: "МОГИЛІВ-ПОДІЛЬСЬКИЙ",
  },
  {
    id: 64,
    parent: 1,
    region: "МУРОВАНІ КУРИЛІВЦІ",
  },
  {
    id: 65,
    parent: 1,
    region: "НЕМИРІВ",
  },
  {
    id: 66,
    parent: 1,
    region: "ОРАТІВ",
  },
  {
    id: 67,
    parent: 1,
    region: "ПІЩАНКА",
  },
  {
    id: 68,
    parent: 1,
    region: "ПОГРЕБИЩЕ",
  },
  {
    id: 69,
    parent: 1,
    region: "ТЕПЛИК",
  },
  {
    id: 73,
    parent: 1,
    region: "ТИВРІВ",
  },
  {
    id: 70,
    parent: 1,
    region: "ТОМАШПІЛЬ",
  },
  {
    id: 71,
    parent: 1,
    region: "ТРОСТЯНЕЦЬ",
  },
  {
    id: 72,
    parent: 1,
    region: "ТУЛЬЧИН",
  },
  {
    id: 55,
    parent: 1,
    region: "ХМІЛЬНИК",
  },
  {
    id: 75,
    parent: 1,
    region: "ЧЕРНІВЦІ (Вінницька обл.)",
  },
  {
    id: 76,
    parent: 1,
    region: "ЧЕЧЕЛЬНИК",
  },
  {
    id: 77,
    parent: 1,
    region: "ШАРГОРОД",
  },
  {
    id: 78,
    parent: 1,
    region: "ЯМПІЛЬ",
  },
  {
    id: 85,
    parent: 2,
    region: "БЕРЕСТЕЧКО",
  },
  {
    id: 80,
    parent: 2,
    region: "ВОЛОДИМИР-ВОЛИНСЬКИЙ",
  },
  {
    id: 84,
    parent: 2,
    region: "ГОРОХІВ",
  },
  {
    id: 86,
    parent: 2,
    region: "ІВАНИЧІ",
  },
  {
    id: 87,
    parent: 2,
    region: "КАМІНЬ-КАШИРСЬКИЙ",
  },
  {
    id: 88,
    parent: 2,
    region: "КІВЕРЦІ",
  },
  {
    id: 81,
    parent: 2,
    region: "КОВЕЛЬ",
  },
  {
    id: 89,
    parent: 2,
    region: "ЛОКАЧІ",
  },
  {
    id: 79,
    parent: 2,
    region: "ЛУЦЬК",
  },
  {
    id: 90,
    parent: 2,
    region: "ЛЮБЕШІВ",
  },
  {
    id: 91,
    parent: 2,
    region: "ЛЮБОМЛЬ",
  },
  {
    id: 92,
    parent: 2,
    region: "МАНЕВИЧІ",
  },
  {
    id: 82,
    parent: 2,
    region: "НОВОВОЛИНСЬК",
  },
  {
    id: 93,
    parent: 2,
    region: "РАТНЕ",
  },
  {
    id: 94,
    parent: 2,
    region: "РОЖИЩЕ",
  },
  {
    id: 95,
    parent: 2,
    region: "СТАРА ВИЖІВКА",
  },
  {
    id: 96,
    parent: 2,
    region: "ТУРІЙСЬК",
  },
  {
    id: 83,
    parent: 2,
    region: "УСТИЛУГ",
  },
  {
    id: 97,
    parent: 2,
    region: "ШАЦЬК",
  },
  {
    id: 111,
    parent: 3,
    region: "АПОСТОЛОВЕ",
  },
  {
    id: 113,
    parent: 3,
    region: "ВАСИЛЬКІВКА",
  },
  {
    id: 115,
    parent: 3,
    region: "ВЕРХІВЦЕВЕ",
  },
  {
    id: 114,
    parent: 3,
    region: "ВЕРХНЬОДНІПРОВСЬК",
  },
  {
    id: 99,
    parent: 3,
    region: "ВІЛЬНОГІРСЬК",
  },
  {
    id: 98,
    parent: 3,
    region: "ДНІПРО",
  },
  {
    id: 101,
    parent: 3,
    region: "ЖОВТІ ВОДИ",
  },
  {
    id: 112,
    parent: 3,
    region: "ЗЕЛЕНОДОЛЬСЬК",
  },
  {
    id: 100,
    parent: 3,
    region: "КАМ'ЯНСЬКЕ",
  },
  {
    id: 102,
    parent: 3,
    region: "КРИВИЙ РІГ",
  },
  {
    id: 117,
    parent: 3,
    region: "КРИНИЧКИ",
  },
  {
    id: 118,
    parent: 3,
    region: "МАГДАЛИНІВКА",
  },
  {
    id: 103,
    parent: 3,
    region: "МАРГАНЕЦЬ",
  },
  {
    id: 119,
    parent: 3,
    region: "МЕЖОВА",
  },
  {
    id: 104,
    parent: 3,
    region: "НІКОПОЛЬ",
  },
  {
    id: 105,
    parent: 3,
    region: "НОВОМОСКОВСЬК",
  },
  {
    id: 124,
    parent: 3,
    region: "П'ЯТИХАТКИ",
  },
  {
    id: 107,
    parent: 3,
    region: "ПАВЛОГРАД",
  },
  {
    id: 120,
    parent: 3,
    region: "ПЕРЕЩЕПИНЕ",
  },
  {
    id: 108,
    parent: 3,
    region: "ПЕРШОТРАВЕНСЬК",
  },
  {
    id: 121,
    parent: 3,
    region: "ПЕТРИКІВКА",
  },
  {
    id: 122,
    parent: 3,
    region: "ПЕТРОПАВЛІВКА",
  },
  {
    id: 116,
    parent: 3,
    region: "ПІДГОРОДНЕ",
  },
  {
    id: 106,
    parent: 3,
    region: "ПОКРОВ",
  },
  {
    id: 123,
    parent: 3,
    region: "ПОКРОВСЬКЕ",
  },
  {
    id: 109,
    parent: 3,
    region: "СИНЕЛЬНИКОВЕ",
  },
  {
    id: 125,
    parent: 3,
    region: "СОЛОНЕ",
  },
  {
    id: 126,
    parent: 3,
    region: "СОФІЇВКА",
  },
  {
    id: 110,
    parent: 3,
    region: "ТЕРНІВКА",
  },
  {
    id: 127,
    parent: 3,
    region: "ТОМАКІВКА",
  },
  {
    id: 128,
    parent: 3,
    region: "ЦАРИЧАНКА",
  },
  {
    id: 129,
    parent: 3,
    region: "ШИРОКЕ",
  },
  {
    id: 130,
    parent: 3,
    region: "ЮР'ЇВКА",
  },
  {
    id: 133,
    parent: 4,
    region: "АВДІЇВКА",
  },
  {
    id: 171,
    parent: 4,
    region: "АМВРОСІЇВКА",
  },
  {
    id: 134,
    parent: 4,
    region: "БАХМУТ",
  },
  {
    id: 141,
    parent: 4,
    region: "БІЛИЦЬКЕ",
  },
  {
    id: 142,
    parent: 4,
    region: "БІЛОЗЕРСЬКЕ",
  },
  {
    id: 188,
    parent: 4,
    region: "БОЙКІВСЬКЕ",
  },
  {
    id: 146,
    parent: 4,
    region: "БУНГЕ",
  },
  {
    id: 177,
    parent: 4,
    region: "ВЕЛИКА НОВОСІЛКА",
  },
  {
    id: 178,
    parent: 4,
    region: "ВОЛНОВАХА",
  },
  {
    id: 172,
    parent: 4,
    region: "ВУГЛЕГІРСЬК",
  },
  {
    id: 164,
    parent: 4,
    region: "ВУГЛЕДАР",
  },
  {
    id: 158,
    parent: 4,
    region: "ГІРНИК",
  },
  {
    id: 135,
    parent: 4,
    region: "ГОРЛІВКА",
  },
  {
    id: 136,
    parent: 4,
    region: "ДЕБАЛЬЦЕВЕ",
  },
  {
    id: 140,
    parent: 4,
    region: "ДОБРОПІЛЛЯ",
  },
  {
    id: 143,
    parent: 4,
    region: "ДОКУЧАЄВСЬК",
  },
  {
    id: 131,
    parent: 4,
    region: "ДОНЕЦЬК",
  },
  {
    id: 144,
    parent: 4,
    region: "ДРУЖКІВКА",
  },
  {
    id: 145,
    parent: 4,
    region: "ЄНАКІЄВЕ",
  },
  {
    id: 147,
    parent: 4,
    region: "ЖДАНІВКА",
  },
  {
    id: 138,
    parent: 4,
    region: "ЗАЛІЗНЕ",
  },
  {
    id: 166,
    parent: 4,
    region: "ЗУГРЕС",
  },
  {
    id: 167,
    parent: 4,
    region: "ІЛОВАЙСЬК",
  },
  {
    id: 187,
    parent: 4,
    region: "КАЛЬМІУСЬКЕ",
  },
  {
    id: 150,
    parent: 4,
    region: "КОСТЯНТИНІВКА",
  },
  {
    id: 151,
    parent: 4,
    region: "КРАМАТОРСЬК",
  },
  {
    id: 181,
    parent: 4,
    region: "КРАСНОГОРІВКА",
  },
  {
    id: 182,
    parent: 4,
    region: "КУРАХОВЕ",
  },
  {
    id: 154,
    parent: 4,
    region: "ЛИМАН",
  },
  {
    id: 155,
    parent: 4,
    region: "МАКІЇВКА",
  },
  {
    id: 184,
    parent: 4,
    region: "МАНГУШ",
  },
  {
    id: 180,
    parent: 4,
    region: "МАР'ЇНКА",
  },
  {
    id: 148,
    parent: 4,
    region: "МАРІУПОЛЬ",
  },
  {
    id: 185,
    parent: 4,
    region: "МИКОЛАЇВКА",
  },
  {
    id: 139,
    parent: 4,
    region: "МИРНОГРАД",
  },
  {
    id: 132,
    parent: 4,
    region: "МОСПИНЕ",
  },
  {
    id: 179,
    parent: 4,
    region: "НІКОЛЬСЬКЕ",
  },
  {
    id: 183,
    parent: 4,
    region: "НОВОАЗОВСЬК",
  },
  {
    id: 156,
    parent: 4,
    region: "НОВОГРОДІВКА",
  },
  {
    id: 170,
    parent: 4,
    region: "ОЛЕКСАНДРІВКА",
  },
  {
    id: 152,
    parent: 4,
    region: "ПОКРОВСЬК",
  },
  {
    id: 153,
    parent: 4,
    region: "РОДИНСЬКЕ",
  },
  {
    id: 174,
    parent: 4,
    region: "СВІТЛОДАРСЬК",
  },
  {
    id: 161,
    parent: 4,
    region: "СВЯТОГІРСЬК",
  },
  {
    id: 157,
    parent: 4,
    region: "СЕЛИДОВЕ",
  },
  {
    id: 173,
    parent: 4,
    region: "СІВЕРСЬК",
  },
  {
    id: 160,
    parent: 4,
    region: "СЛОВ'ЯНСЬК",
  },
  {
    id: 162,
    parent: 4,
    region: "СНІЖНЕ",
  },
  {
    id: 175,
    parent: 4,
    region: "СОЛЕДАР",
  },
  {
    id: 186,
    parent: 4,
    region: "СТАРОБЕШЕВЕ",
  },
  {
    id: 137,
    parent: 4,
    region: "ТОРЕЦЬК",
  },
  {
    id: 159,
    parent: 4,
    region: "УКРАЇНСЬК",
  },
  {
    id: 165,
    parent: 4,
    region: "ХАРЦИЗЬК",
  },
  {
    id: 149,
    parent: 4,
    region: "ХРЕСТІВКА",
  },
  {
    id: 176,
    parent: 4,
    region: "ЧАСІВ ЯР",
  },
  {
    id: 163,
    parent: 4,
    region: "ЧИСТЯКОВЕ",
  },
  {
    id: 168,
    parent: 4,
    region: "ШАХТАРСЬК",
  },
  {
    id: 169,
    parent: 4,
    region: "ЯСИНУВАТА",
  },
  {
    id: 194,
    parent: 5,
    region: "АНДРУШІВКА",
  },
  {
    id: 195,
    parent: 5,
    region: "БАРАНІВКА",
  },
  {
    id: 190,
    parent: 5,
    region: "БЕРДИЧІВ",
  },
  {
    id: 196,
    parent: 5,
    region: "БРУСИЛІВ",
  },
  {
    id: 199,
    parent: 5,
    region: "ЄМІЛЬЧИНЕ",
  },
  {
    id: 189,
    parent: 5,
    region: "ЖИТОМИР",
  },
  {
    id: 191,
    parent: 5,
    region: "КОРОСТЕНЬ",
  },
  {
    id: 200,
    parent: 5,
    region: "КОРОСТИШІВ",
  },
  {
    id: 201,
    parent: 5,
    region: "ЛУГИНИ",
  },
  {
    id: 202,
    parent: 5,
    region: "ЛЮБАР",
  },
  {
    id: 192,
    parent: 5,
    region: "МАЛИН",
  },
  {
    id: 203,
    parent: 5,
    region: "НАРОДИЧІ",
  },
  {
    id: 193,
    parent: 5,
    region: "НОВОГРАД-ВОЛИНСЬКИЙ",
  },
  {
    id: 204,
    parent: 5,
    region: "ОВРУЧ",
  },
  {
    id: 205,
    parent: 5,
    region: "ОЛЕВСЬК",
  },
  {
    id: 206,
    parent: 5,
    region: "ПОПІЛЬНЯ",
  },
  {
    id: 209,
    parent: 5,
    region: "ПУЛИНИ",
  },
  {
    id: 207,
    parent: 5,
    region: "РАДОМИШЛЬ",
  },
  {
    id: 198,
    parent: 5,
    region: "РОМАНІВ",
  },
  {
    id: 208,
    parent: 5,
    region: "РУЖИН",
  },
  {
    id: 197,
    parent: 5,
    region: "ХОРОШІВ",
  },
  {
    id: 210,
    parent: 5,
    region: "ЧЕРНЯХІВ",
  },
  {
    id: 211,
    parent: 5,
    region: "ЧУДНІВ",
  },
  {
    id: 213,
    parent: 6,
    region: "БЕРЕГОВЕ",
  },
  {
    id: 217,
    parent: 6,
    region: "ВЕЛИКИЙ БЕРЕЗНИЙ",
  },
  {
    id: 218,
    parent: 6,
    region: "ВИНОГРАДІВ",
  },
  {
    id: 219,
    parent: 6,
    region: "ВОЛОВЕЦЬ",
  },
  {
    id: 220,
    parent: 6,
    region: "ІРШАВА",
  },
  {
    id: 221,
    parent: 6,
    region: "МІЖГІР'Я",
  },
  {
    id: 214,
    parent: 6,
    region: "МУКАЧЕВО",
  },
  {
    id: 222,
    parent: 6,
    region: "ПЕРЕЧИН",
  },
  {
    id: 223,
    parent: 6,
    region: "РАХІВ",
  },
  {
    id: 224,
    parent: 6,
    region: "СВАЛЯВА",
  },
  {
    id: 225,
    parent: 6,
    region: "ТЯЧІВ",
  },
  {
    id: 212,
    parent: 6,
    region: "УЖГОРОД",
  },
  {
    id: 215,
    parent: 6,
    region: "ХУСТ",
  },
  {
    id: 216,
    parent: 6,
    region: "ЧОП",
  },
  {
    id: 227,
    parent: 7,
    region: "БЕРДЯНСЬК",
  },
  {
    id: 239,
    parent: 7,
    region: "БІЛЬМАК",
  },
  {
    id: 232,
    parent: 7,
    region: "ВАСИЛІВКА",
  },
  {
    id: 234,
    parent: 7,
    region: "ВЕЛИКА БІЛОЗЕРКА",
  },
  {
    id: 235,
    parent: 7,
    region: "ВЕСЕЛЕ",
  },
  {
    id: 236,
    parent: 7,
    region: "ВІЛЬНЯНСЬК",
  },
  {
    id: 237,
    parent: 7,
    region: "ГУЛЯЙПОЛЕ",
  },
  {
    id: 233,
    parent: 7,
    region: "ДНІПРОРУДНЕ",
  },
  {
    id: 230,
    parent: 7,
    region: "ЕНЕРГОДАР",
  },
  {
    id: 226,
    parent: 7,
    region: "ЗАПОРІЖЖЯ",
  },
  {
    id: 238,
    parent: 7,
    region: "КАМ'ЯНКА-ДНІПРОВСЬКА",
  },
  {
    id: 228,
    parent: 7,
    region: "МЕЛІТОПОЛЬ",
  },
  {
    id: 240,
    parent: 7,
    region: "МИХАЙЛІВКА",
  },
  {
    id: 247,
    parent: 7,
    region: "МОЛОЧАНСЬК",
  },
  {
    id: 241,
    parent: 7,
    region: "НОВОМИКОЛАЇВКА",
  },
  {
    id: 242,
    parent: 7,
    region: "ОРІХІВ",
  },
  {
    id: 243,
    parent: 7,
    region: "ПОЛОГИ",
  },
  {
    id: 244,
    parent: 7,
    region: "ПРИАЗОВСЬКЕ",
  },
  {
    id: 245,
    parent: 7,
    region: "ПРИМОРСЬК",
  },
  {
    id: 246,
    parent: 7,
    region: "РОЗІВКА",
  },
  {
    id: 229,
    parent: 7,
    region: "ТОКМАК",
  },
  {
    id: 248,
    parent: 7,
    region: "ЧЕРНІГІВКА",
  },
  {
    id: 231,
    parent: 7,
    region: "ЯКИМІВКА",
  },
  {
    id: 255,
    parent: 8,
    region: "БОГОРОДЧАНИ",
  },
  {
    id: 250,
    parent: 8,
    region: "БОЛЕХІВ",
  },
  {
    id: 251,
    parent: 8,
    region: "БУРШТИН",
  },
  {
    id: 256,
    parent: 8,
    region: "ВЕРХОВИНА",
  },
  {
    id: 257,
    parent: 8,
    region: "ГАЛИЧ",
  },
  {
    id: 258,
    parent: 8,
    region: "ГОРОДЕНКА",
  },
  {
    id: 259,
    parent: 8,
    region: "ДОЛИНА",
  },
  {
    id: 249,
    parent: 8,
    region: "ІВАНО-ФРАНКІВСЬК",
  },
  {
    id: 252,
    parent: 8,
    region: "КАЛУШ",
  },
  {
    id: 253,
    parent: 8,
    region: "КОЛОМИЯ",
  },
  {
    id: 260,
    parent: 8,
    region: "КОСІВ",
  },
  {
    id: 261,
    parent: 8,
    region: "НАДВІРНА",
  },
  {
    id: 262,
    parent: 8,
    region: "РОГАТИН",
  },
  {
    id: 263,
    parent: 8,
    region: "РОЖНЯТІВ",
  },
  {
    id: 264,
    parent: 8,
    region: "СНЯТИН",
  },
  {
    id: 266,
    parent: 8,
    region: "ТИСМЕНИЦЯ",
  },
  {
    id: 265,
    parent: 8,
    region: "ТЛУМАЧ",
  },
  {
    id: 254,
    parent: 8,
    region: "ЯРЕМЧЕ",
  },
  {
    id: 280,
    parent: 9,
    region: "БАРИШІВКА",
  },
  {
    id: 268,
    parent: 9,
    region: "БЕРЕЗАНЬ",
  },
  {
    id: 267,
    parent: 9,
    region: "БІЛА ЦЕРКВА",
  },
  {
    id: 282,
    parent: 9,
    region: "БОГУСЛАВ",
  },
  {
    id: 269,
    parent: 9,
    region: "БОРИСПІЛЬ",
  },
  {
    id: 283,
    parent: 9,
    region: "БОРОДЯНКА",
  },
  {
    id: 291,
    parent: 9,
    region: "БОЯРКА",
  },
  {
    id: 270,
    parent: 9,
    region: "БРОВАРИ",
  },
  {
    id: 272,
    parent: 9,
    region: "БУЧА",
  },
  {
    id: 271,
    parent: 9,
    region: "ВАСИЛЬКІВ",
  },
  {
    id: 285,
    parent: 9,
    region: "ВИШГОРОД",
  },
  {
    id: 292,
    parent: 9,
    region: "ВИШНЕВЕ",
  },
  {
    id: 284,
    parent: 9,
    region: "ВОЛОДАРКА",
  },
  {
    id: 286,
    parent: 9,
    region: "ЗГУРІВКА",
  },
  {
    id: 287,
    parent: 9,
    region: "ІВАНКІВ",
  },
  {
    id: 273,
    parent: 9,
    region: "ІРПІНЬ",
  },
  {
    id: 289,
    parent: 9,
    region: "КАГАРЛИК",
  },
  {
    id: 290,
    parent: 9,
    region: "КИЇВ",
  },
  {
    id: 296,
    parent: 9,
    region: "КРАСЯТИЧІ",
  },
  {
    id: 293,
    parent: 9,
    region: "МАКАРІВ",
  },
  {
    id: 294,
    parent: 9,
    region: "МИРОНІВКА",
  },
  {
    id: 279,
    parent: 9,
    region: "ОБУХІВ",
  },
  {
    id: 274,
    parent: 9,
    region: "ПЕРЕЯСЛАВ-ХМЕЛЬНИЦЬКИЙ",
  },
  {
    id: 275,
    parent: 9,
    region: "ПРИП'ЯТЬ",
  },
  {
    id: 277,
    parent: 9,
    region: "РЖИЩІВ",
  },
  {
    id: 297,
    parent: 9,
    region: "РОКИТНЕ",
  },
  {
    id: 298,
    parent: 9,
    region: "СКВИРА",
  },
  {
    id: 278,
    parent: 9,
    region: "СЛАВУТИЧ",
  },
  {
    id: 299,
    parent: 9,
    region: "СТАВИЩЕ",
  },
  {
    id: 300,
    parent: 9,
    region: "ТАРАЩА",
  },
  {
    id: 301,
    parent: 9,
    region: "ТЕТІЇВ",
  },
  {
    id: 281,
    parent: 9,
    region: "УЗИН",
  },
  {
    id: 295,
    parent: 9,
    region: "УКРАЇНКА",
  },
  {
    id: 276,
    parent: 9,
    region: "ФАСТІВ",
  },
  {
    id: 288,
    parent: 9,
    region: "ЧОРНОБИЛЬ",
  },
  {
    id: 302,
    parent: 9,
    region: "ЯГОТИН",
  },
  {
    id: 322,
    parent: 10,
    region: "БЛАГОВІЩЕНСЬКЕ",
  },
  {
    id: 307,
    parent: 10,
    region: "БОБРИНЕЦЬ",
  },
  {
    id: 319,
    parent: 10,
    region: "ВІЛЬШАНКА",
  },
  {
    id: 308,
    parent: 10,
    region: "ГАЙВОРОН",
  },
  {
    id: 309,
    parent: 10,
    region: "ГОЛОВАНІВСЬК",
  },
  {
    id: 310,
    parent: 10,
    region: "ДОБРОВЕЛИЧКІВКА",
  },
  {
    id: 312,
    parent: 10,
    region: "ДОЛИНСЬКА",
  },
  {
    id: 305,
    parent: 10,
    region: "ЗНАМ'ЯНКА",
  },
  {
    id: 313,
    parent: 10,
    region: "КОМПАНІЇВКА",
  },
  {
    id: 303,
    parent: 10,
    region: "КРОПИВНИЦЬКИЙ",
  },
  {
    id: 314,
    parent: 10,
    region: "МАЛА ВИСКА",
  },
  {
    id: 315,
    parent: 10,
    region: "НОВГОРОДКА",
  },
  {
    id: 316,
    parent: 10,
    region: "НОВОАРХАНГЕЛЬСЬК",
  },
  {
    id: 317,
    parent: 10,
    region: "НОВОМИРГОРОД",
  },
  {
    id: 318,
    parent: 10,
    region: "НОВОУКРАЇНКА",
  },
  {
    id: 304,
    parent: 10,
    region: "ОЛЕКСАНДРІЯ",
  },
  {
    id: 320,
    parent: 10,
    region: "ОНУФРІЇВКА",
  },
  {
    id: 321,
    parent: 10,
    region: "ПЕТРОВЕ",
  },
  {
    id: 311,
    parent: 10,
    region: "ПОМІЧНА",
  },
  {
    id: 306,
    parent: 10,
    region: "СВІТЛОВОДСЬК",
  },
  {
    id: 323,
    parent: 10,
    region: "УСТИНІВКА",
  },
  {
    id: 347,
    parent: 11,
    region: "АЛМАЗНА",
  },
  {
    id: 329,
    parent: 11,
    region: "АЛЧЕВСЬК",
  },
  {
    id: 326,
    parent: 11,
    region: "АНТРАЦИТ",
  },
  {
    id: 349,
    parent: 11,
    region: "БІЛОВОДСЬК",
  },
  {
    id: 350,
    parent: 11,
    region: "БІЛОКУРАКИНЕ",
  },
  {
    id: 334,
    parent: 11,
    region: "БОКОВО-ХРУСТАЛЬНЕ",
  },
  {
    id: 327,
    parent: 11,
    region: "БРЯНКА",
  },
  {
    id: 344,
    parent: 11,
    region: "ВОЗНЕСЕНІВКА",
  },
  {
    id: 362,
    parent: 11,
    region: "ГІРСЬКЕ",
  },
  {
    id: 328,
    parent: 11,
    region: "ГОЛУБІВКА",
  },
  {
    id: 343,
    parent: 11,
    region: "ДОВЖАНСЬК",
  },
  {
    id: 366,
    parent: 11,
    region: "ЗИМОГІР'Я",
  },
  {
    id: 363,
    parent: 11,
    region: "ЗОЛОТЕ",
  },
  {
    id: 360,
    parent: 11,
    region: "ЗОРИНСЬК",
  },
  {
    id: 348,
    parent: 11,
    region: "ІРМІНО",
  },
  {
    id: 346,
    parent: 11,
    region: "КАДІЇВКА",
  },
  {
    id: 359,
    parent: 11,
    region: "КИПУЧЕ",
  },
  {
    id: 351,
    parent: 11,
    region: "КРЕМІННА",
  },
  {
    id: 337,
    parent: 11,
    region: "ЛИСИЧАНСЬК",
  },
  {
    id: 324,
    parent: 11,
    region: "ЛУГАНСЬК",
  },
  {
    id: 352,
    parent: 11,
    region: "ЛУТУГИНЕ",
  },
  {
    id: 353,
    parent: 11,
    region: "МАРКІВКА",
  },
  {
    id: 354,
    parent: 11,
    region: "МІЛОВЕ",
  },
  {
    id: 335,
    parent: 11,
    region: "МІУСИНСЬК",
  },
  {
    id: 331,
    parent: 11,
    region: "МОЛОДОГВАРДІЙСЬК",
  },
  {
    id: 355,
    parent: 11,
    region: "НОВОАЙДАР",
  },
  {
    id: 338,
    parent: 11,
    region: "НОВОДРУЖЕСЬК",
  },
  {
    id: 357,
    parent: 11,
    region: "НОВОПСКОВ",
  },
  {
    id: 325,
    parent: 11,
    region: "ОЛЕКСАНДРІВСЬК",
  },
  {
    id: 340,
    parent: 11,
    region: "ПЕРВОМАЙСЬК",
  },
  {
    id: 358,
    parent: 11,
    region: "ПЕРЕВАЛЬСЬК",
  },
  {
    id: 336,
    parent: 11,
    region: "ПЕТРОВО-КРАСНОСІЛЛЯ",
  },
  {
    id: 361,
    parent: 11,
    region: "ПОПАСНА",
  },
  {
    id: 339,
    parent: 11,
    region: "ПРИВІЛЛЯ",
  },
  {
    id: 341,
    parent: 11,
    region: "РОВЕНЬКИ",
  },
  {
    id: 342,
    parent: 11,
    region: "РУБІЖНЕ",
  },
  {
    id: 364,
    parent: 11,
    region: "СВАТОВЕ",
  },
  {
    id: 345,
    parent: 11,
    region: "СЄВЄРОДОНЕЦЬК",
  },
  {
    id: 365,
    parent: 11,
    region: "СЛОВ'ЯНОСЕРБСЬК",
  },
  {
    id: 330,
    parent: 11,
    region: "СОРОКИНЕ",
  },
  {
    id: 367,
    parent: 11,
    region: "СТАНИЦЯ ЛУГАНСЬКА",
  },
  {
    id: 368,
    parent: 11,
    region: "СТАРОБІЛЬСЬК",
  },
  {
    id: 332,
    parent: 11,
    region: "СУХОДІЛЬСЬК",
  },
  {
    id: 369,
    parent: 11,
    region: "ТРОЇЦЬКЕ",
  },
  {
    id: 333,
    parent: 11,
    region: "ХРУСТАЛЬНИЙ",
  },
  {
    id: 356,
    parent: 11,
    region: "ЩАСТЯ",
  },
  {
    id: 405,
    parent: 12,
    region: "БЕЛЗ",
  },
  {
    id: 398,
    parent: 12,
    region: "БІБРКА",
  },
  {
    id: 372,
    parent: 12,
    region: "БОРИСЛАВ",
  },
  {
    id: 382,
    parent: 12,
    region: "БРОДИ",
  },
  {
    id: 383,
    parent: 12,
    region: "БУСЬК",
  },
  {
    id: 406,
    parent: 12,
    region: "ВЕЛИКІ МОСТИ",
  },
  {
    id: 371,
    parent: 12,
    region: "ВИННИКИ",
  },
  {
    id: 389,
    parent: 12,
    region: "ГЛИНЯНИ",
  },
  {
    id: 384,
    parent: 12,
    region: "ГОРОДОК",
  },
  {
    id: 409,
    parent: 12,
    region: "ДОБРОМИЛЬ",
  },
  {
    id: 373,
    parent: 12,
    region: "ДРОГОБИЧ",
  },
  {
    id: 394,
    parent: 12,
    region: "ДУБЛЯНИ",
  },
  {
    id: 386,
    parent: 12,
    region: "ЖИДАЧІВ",
  },
  {
    id: 393,
    parent: 12,
    region: "ЖОВКВА",
  },
  {
    id: 388,
    parent: 12,
    region: "ЗОЛОЧІВ",
  },
  {
    id: 390,
    parent: 12,
    region: "КАМ'ЯНКА-БУЗЬКА",
  },
  {
    id: 385,
    parent: 12,
    region: "КОМАРНО",
  },
  {
    id: 370,
    parent: 12,
    region: "ЛЬВІВ",
  },
  {
    id: 396,
    parent: 12,
    region: "МИКОЛАЇВ (Львівська обл.)",
  },
  {
    id: 375,
    parent: 12,
    region: "МОРШИН",
  },
  {
    id: 391,
    parent: 12,
    region: "МОСТИСЬКА",
  },
  {
    id: 401,
    parent: 12,
    region: "НОВИЙ КАЛИНІВ",
  },
  {
    id: 376,
    parent: 12,
    region: "НОВИЙ РОЗДІЛ",
  },
  {
    id: 413,
    parent: 12,
    region: "НОВОЯВОРІВСЬК",
  },
  {
    id: 397,
    parent: 12,
    region: "ПЕРЕМИШЛЯНИ",
  },
  {
    id: 399,
    parent: 12,
    region: "ПУСТОМИТИ",
  },
  {
    id: 395,
    parent: 12,
    region: "РАВА-РУСЬКА",
  },
  {
    id: 400,
    parent: 12,
    region: "РАДЕХІВ",
  },
  {
    id: 402,
    parent: 12,
    region: "РУДКИ",
  },
  {
    id: 377,
    parent: 12,
    region: "САМБІР",
  },
  {
    id: 403,
    parent: 12,
    region: "СКОЛЕ",
  },
  {
    id: 404,
    parent: 12,
    region: "СОКАЛЬ",
  },
  {
    id: 381,
    parent: 12,
    region: "СОСНІВКА",
  },
  {
    id: 408,
    parent: 12,
    region: "СТАРИЙ САМБІР",
  },
  {
    id: 374,
    parent: 12,
    region: "СТЕБНИК",
  },
  {
    id: 378,
    parent: 12,
    region: "СТРИЙ",
  },
  {
    id: 392,
    parent: 12,
    region: "СУДОВА ВИШНЯ",
  },
  {
    id: 379,
    parent: 12,
    region: "ТРУСКАВЕЦЬ",
  },
  {
    id: 411,
    parent: 12,
    region: "ТУРКА",
  },
  {
    id: 407,
    parent: 12,
    region: "УГНІВ",
  },
  {
    id: 410,
    parent: 12,
    region: "ХИРІВ",
  },
  {
    id: 387,
    parent: 12,
    region: "ХОДОРІВ",
  },
  {
    id: 380,
    parent: 12,
    region: "ЧЕРВОНОГРАД",
  },
  {
    id: 412,
    parent: 12,
    region: "ЯВОРІВ",
  },
  {
    id: 417,
    parent: 13,
    region: "АРБУЗИНКА",
  },
  {
    id: 418,
    parent: 13,
    region: "БАШТАНКА",
  },
  {
    id: 419,
    parent: 13,
    region: "БЕРЕЗАНКА",
  },
  {
    id: 420,
    parent: 13,
    region: "БЕРЕЗНЕГУВАТЕ",
  },
  {
    id: 421,
    parent: 13,
    region: "БРАТСЬКЕ",
  },
  {
    id: 422,
    parent: 13,
    region: "ВЕСЕЛИНОВЕ",
  },
  {
    id: 414,
    parent: 13,
    region: "ВОЗНЕСЕНСЬК",
  },
  {
    id: 423,
    parent: 13,
    region: "ВРАДІЇВКА",
  },
  {
    id: 424,
    parent: 13,
    region: "ДОМАНІВКА",
  },
  {
    id: 425,
    parent: 13,
    region: "ЄЛАНЕЦЬ",
  },
  {
    id: 426,
    parent: 13,
    region: "КАЗАНКА",
  },
  {
    id: 427,
    parent: 13,
    region: "КРИВЕ ОЗЕРО",
  },
  {
    id: 429,
    parent: 13,
    region: "НОВА ОДЕСА",
  },
  {
    id: 778,
    parent: 13,
    region: "МИКОЛАЇВ",
  },
  {
    id: 428,
    parent: 13,
    region: "НОВИЙ БУГ",
  },
  {
    id: 415,
    parent: 13,
    region: "ОЧАКІВ",
  },
  {
    id: 430,
    parent: 13,
    region: "СНІГУРІВКА",
  },
  {
    id: 416,
    parent: 13,
    region: "ЮЖНОУКРАЇНСЬК",
  },
  {
    id: 440,
    parent: 14,
    region: "АНАНЬЇВ",
  },
  {
    id: 441,
    parent: 14,
    region: "АРЦИЗ",
  },
  {
    id: 432,
    parent: 14,
    region: "БАЛТА",
  },
  {
    id: 442,
    parent: 14,
    region: "БЕРЕЗІВКА",
  },
  {
    id: 433,
    parent: 14,
    region: "БІЛГОРОД-ДНІСТРОВСЬКИЙ",
  },
  {
    id: 434,
    parent: 14,
    region: "БІЛЯЇВКА",
  },
  {
    id: 443,
    parent: 14,
    region: "БОЛГРАД",
  },
  {
    id: 444,
    parent: 14,
    region: "ВЕЛИКА МИХАЙЛІВКА",
  },
  {
    id: 447,
    parent: 14,
    region: "ВИЛКОВЕ",
  },
  {
    id: 449,
    parent: 14,
    region: "ДОБРОСЛАВ",
  },
  {
    id: 459,
    parent: 14,
    region: "ЗАХАРІВКА",
  },
  {
    id: 445,
    parent: 14,
    region: "ІВАНІВКА",
  },
  {
    id: 435,
    parent: 14,
    region: "ІЗМАЇЛ",
  },
  {
    id: 446,
    parent: 14,
    region: "КІЛІЯ",
  },
  {
    id: 448,
    parent: 14,
    region: "КОДИМА",
  },
  {
    id: 451,
    parent: 14,
    region: "ЛЮБАШІВКА",
  },
  {
    id: 452,
    parent: 14,
    region: "ОВІДІОПОЛЬ",
  },
  {
    id: 431,
    parent: 14,
    region: "ОДЕСА",
  },
  {
    id: 450,
    parent: 14,
    region: "ОКНИ",
  },
  {
    id: 437,
    parent: 14,
    region: "ПОДІЛЬСЬК",
  },
  {
    id: 454,
    parent: 14,
    region: "РЕНІ",
  },
  {
    id: 453,
    parent: 14,
    region: "РОЗДІЛЬНА",
  },
  {
    id: 455,
    parent: 14,
    region: "САВРАНЬ",
  },
  {
    id: 456,
    parent: 14,
    region: "САРАТА",
  },
  {
    id: 457,
    parent: 14,
    region: "ТАРУТИНЕ",
  },
  {
    id: 458,
    parent: 14,
    region: "ТАТАРБУНАРИ",
  },
  {
    id: 438,
    parent: 14,
    region: "ТЕПЛОДАР",
  },
  {
    id: 436,
    parent: 14,
    region: "ЧОРНОМОРСЬК",
  },
  {
    id: 460,
    parent: 14,
    region: "ШИРЯЄВЕ",
  },
  {
    id: 439,
    parent: 14,
    region: "ЮЖНЕ",
  },
  {
    id: 467,
    parent: 15,
    region: "ВЕЛИКА БАГАЧКА",
  },
  {
    id: 463,
    parent: 15,
    region: "ГАДЯЧ",
  },
  {
    id: 468,
    parent: 15,
    region: "ГЛОБИНЕ",
  },
  {
    id: 462,
    parent: 15,
    region: "ГОРІШНІ ПЛАВНІ",
  },
  {
    id: 469,
    parent: 15,
    region: "ГРЕБІНКА",
  },
  {
    id: 470,
    parent: 15,
    region: "ДИКАНЬКА",
  },
  {
    id: 477,
    parent: 15,
    region: "ЗАВОДСЬКЕ",
  },
  {
    id: 471,
    parent: 15,
    region: "ЗІНЬКІВ",
  },
  {
    id: 472,
    parent: 15,
    region: "КАРЛІВКА",
  },
  {
    id: 473,
    parent: 15,
    region: "КОБЕЛЯКИ",
  },
  {
    id: 474,
    parent: 15,
    region: "КОЗЕЛЬЩИНА",
  },
  {
    id: 475,
    parent: 15,
    region: "КОТЕЛЬВА",
  },
  {
    id: 464,
    parent: 15,
    region: "КРЕМЕНЧУК",
  },
  {
    id: 476,
    parent: 15,
    region: "ЛОХВИЦЯ",
  },
  {
    id: 465,
    parent: 15,
    region: "ЛУБНИ",
  },
  {
    id: 478,
    parent: 15,
    region: "МАШІВКА",
  },
  {
    id: 466,
    parent: 15,
    region: "МИРГОРОД",
  },
  {
    id: 479,
    parent: 15,
    region: "НОВІ САНЖАРИ",
  },
  {
    id: 480,
    parent: 15,
    region: "ОРЖИЦЯ",
  },
  {
    id: 481,
    parent: 15,
    region: "ПИРЯТИН",
  },
  {
    id: 461,
    parent: 15,
    region: "ПОЛТАВА",
  },
  {
    id: 482,
    parent: 15,
    region: "РЕШЕТИЛІВКА",
  },
  {
    id: 483,
    parent: 15,
    region: "СЕМЕНІВКА",
  },
  {
    id: 484,
    parent: 15,
    region: "ХОРОЛ",
  },
  {
    id: 485,
    parent: 15,
    region: "ЧОРНУХИ",
  },
  {
    id: 486,
    parent: 15,
    region: "ЧУТОВЕ",
  },
  {
    id: 487,
    parent: 15,
    region: "ШИШАКИ",
  },
  {
    id: 492,
    parent: 16,
    region: "БЕРЕЗНЕ",
  },
  {
    id: 490,
    parent: 16,
    region: "ВАРАШ",
  },
  {
    id: 493,
    parent: 16,
    region: "ВОЛОДИМИРЕЦЬ",
  },
  {
    id: 494,
    parent: 16,
    region: "ГОЩА",
  },
  {
    id: 495,
    parent: 16,
    region: "ДЕМИДІВКА",
  },
  {
    id: 489,
    parent: 16,
    region: "ДУБНО",
  },
  {
    id: 496,
    parent: 16,
    region: "ДУБРОВИЦЯ",
  },
  {
    id: 497,
    parent: 16,
    region: "ЗАРІЧНЕ",
  },
  {
    id: 498,
    parent: 16,
    region: "ЗДОЛБУНІВ",
  },
  {
    id: 499,
    parent: 16,
    region: "КОРЕЦЬ",
  },
  {
    id: 500,
    parent: 16,
    region: "КОСТОПІЛЬ",
  },
  {
    id: 501,
    parent: 16,
    region: "МЛИНІВ",
  },
  {
    id: 491,
    parent: 16,
    region: "ОСТРОГ",
  },
  {
    id: 503,
    parent: 16,
    region: "РАДИВИЛІВ",
  },
  {
    id: 488,
    parent: 16,
    region: "РІВНЕ",
  },
  {
    id: 502,
    parent: 16,
    region: "САРНИ",
  },
  {
    id: 511,
    parent: 17,
    region: "БІЛОПІЛЛЯ",
  },
  {
    id: 513,
    parent: 17,
    region: "БУРИНЬ",
  },
  {
    id: 514,
    parent: 17,
    region: "ВЕЛИКА ПИСАРІВКА",
  },
  {
    id: 512,
    parent: 17,
    region: "ВОРОЖБА",
  },
  {
    id: 506,
    parent: 17,
    region: "ГЛУХІВ",
  },
  {
    id: 521,
    parent: 17,
    region: "ДРУЖБА",
  },
  {
    id: 507,
    parent: 17,
    region: "КОНОТОП",
  },
  {
    id: 515,
    parent: 17,
    region: "КРАСНОПІЛЛЯ",
  },
  {
    id: 516,
    parent: 17,
    region: "КРОЛЕВЕЦЬ",
  },
  {
    id: 508,
    parent: 17,
    region: "ЛЕБЕДИН",
  },
  {
    id: 517,
    parent: 17,
    region: "ЛИПОВА ДОЛИНА",
  },
  {
    id: 518,
    parent: 17,
    region: "НЕДРИГАЙЛІВ",
  },
  {
    id: 505,
    parent: 17,
    region: "ОХТИРКА",
  },
  {
    id: 519,
    parent: 17,
    region: "ПУТИВЛЬ",
  },
  {
    id: 509,
    parent: 17,
    region: "РОМНИ",
  },
  {
    id: 520,
    parent: 17,
    region: "СЕРЕДИНА-БУДА",
  },
  {
    id: 504,
    parent: 17,
    region: "СУМИ",
  },
  {
    id: 510,
    parent: 17,
    region: "ШОСТКА",
  },
  {
    id: 524,
    parent: 18,
    region: "БЕРЕЖАНИ",
  },
  {
    id: 526,
    parent: 18,
    region: "БОРЩІВ",
  },
  {
    id: 527,
    parent: 18,
    region: "БУЧАЧ",
  },
  {
    id: 528,
    parent: 18,
    region: "ГУСЯТИН",
  },
  {
    id: 531,
    parent: 18,
    region: "ЗАЛІЩИКИ",
  },
  {
    id: 532,
    parent: 18,
    region: "ЗБАРАЖ",
  },
  {
    id: 533,
    parent: 18,
    region: "ЗБОРІВ",
  },
  {
    id: 534,
    parent: 18,
    region: "КОЗОВА",
  },
  {
    id: 529,
    parent: 18,
    region: "КОПИЧИНЦІ",
  },
  {
    id: 525,
    parent: 18,
    region: "КРЕМЕНЕЦЬ",
  },
  {
    id: 536,
    parent: 18,
    region: "ЛАНІВЦІ",
  },
  {
    id: 537,
    parent: 18,
    region: "МОНАСТИРИСЬКА",
  },
  {
    id: 538,
    parent: 18,
    region: "ПІДВОЛОЧИСЬК",
  },
  {
    id: 540,
    parent: 18,
    region: "ПІДГАЙЦІ",
  },
  {
    id: 535,
    parent: 18,
    region: "ПОЧАЇВ",
  },
  {
    id: 539,
    parent: 18,
    region: "СКАЛАТ",
  },
  {
    id: 541,
    parent: 18,
    region: "ТЕРЕБОВЛЯ",
  },
  {
    id: 522,
    parent: 18,
    region: "ТЕРНОПІЛЬ",
  },
  {
    id: 530,
    parent: 18,
    region: "ХОРОСТКІВ",
  },
  {
    id: 523,
    parent: 18,
    region: "ЧОРТКІВ",
  },
  {
    id: 542,
    parent: 18,
    region: "ШУМСЬК",
  },
  {
    id: 550,
    parent: 19,
    region: "БАЛАКЛІЯ",
  },
  {
    id: 551,
    parent: 19,
    region: "БАРВІНКОВЕ",
  },
  {
    id: 552,
    parent: 19,
    region: "БЛИЗНЮКИ",
  },
  {
    id: 553,
    parent: 19,
    region: "БОГОДУХІВ",
  },
  {
    id: 554,
    parent: 19,
    region: "БОРОВА",
  },
  {
    id: 555,
    parent: 19,
    region: "ВАЛКИ",
  },
  {
    id: 556,
    parent: 19,
    region: "ВЕЛИКИЙ БУРЛУК",
  },
  {
    id: 557,
    parent: 19,
    region: "ВОВЧАНСЬК",
  },
  {
    id: 559,
    parent: 19,
    region: "ДВОРІЧНА",
  },
  {
    id: 560,
    parent: 19,
    region: "ДЕРГАЧІ",
  },
  {
    id: 561,
    parent: 19,
    region: "ЗАЧЕПИЛІВКА",
  },
  {
    id: 558,
    parent: 19,
    region: "ЗМІЇВ",
  },
  {
    id: 544,
    parent: 19,
    region: "ІЗЮМ",
  },
  {
    id: 562,
    parent: 19,
    region: "КЕГИЧІВКА",
  },
  {
    id: 563,
    parent: 19,
    region: "КОЛОМАК",
  },
  {
    id: 564,
    parent: 19,
    region: "КРАСНОГРАД",
  },
  {
    id: 565,
    parent: 19,
    region: "КРАСНОКУТСЬК",
  },
  {
    id: 566,
    parent: 19,
    region: "КРАСНОПАВЛІВКА",
  },
  {
    id: 545,
    parent: 19,
    region: "КУП'ЯНСЬК",
  },
  {
    id: 546,
    parent: 19,
    region: "ЛОЗОВА",
  },
  {
    id: 547,
    parent: 19,
    region: "ЛЮБОТИН",
  },
  {
    id: 570,
    parent: 19,
    region: "МЕРЕФА",
  },
  {
    id: 567,
    parent: 19,
    region: "НОВА ВОДОЛАГА",
  },
  {
    id: 548,
    parent: 19,
    region: "ПЕРВОМАЙСЬКИЙ",
  },
  {
    id: 568,
    parent: 19,
    region: "ПЕЧЕНІГИ",
  },
  {
    id: 571,
    parent: 19,
    region: "ПІВДЕННЕ",
  },
  {
    id: 569,
    parent: 19,
    region: "САХНОВЩИНА",
  },
  {
    id: 543,
    parent: 19,
    region: "ХАРКІВ",
  },
  {
    id: 549,
    parent: 19,
    region: "ЧУГУЇВ",
  },
  {
    id: 572,
    parent: 19,
    region: "ШЕВЧЕНКОВЕ",
  },
  {
    id: 579,
    parent: 20,
    region: "БЕРИСЛАВ",
  },
  {
    id: 578,
    parent: 20,
    region: "БІЛОЗЕРКА",
  },
  {
    id: 581,
    parent: 20,
    region: "ВЕЛИКА ЛЕПЕТИХА",
  },
  {
    id: 580,
    parent: 20,
    region: "ВЕЛИКА ОЛЕКСАНДРІВКА",
  },
  {
    id: 582,
    parent: 20,
    region: "ВЕРХНІЙ РОГАЧИК",
  },
  {
    id: 583,
    parent: 20,
    region: "ВИСОКОПІЛЛЯ",
  },
  {
    id: 584,
    parent: 20,
    region: "ГЕНІЧЕСЬК",
  },
  {
    id: 574,
    parent: 20,
    region: "ГОЛА ПРИСТАНЬ",
  },
  {
    id: 585,
    parent: 20,
    region: "ГОРНОСТАЇВКА",
  },
  {
    id: 586,
    parent: 20,
    region: "КАЛАНЧАК",
  },
  {
    id: 575,
    parent: 20,
    region: "КАХОВКА",
  },
  {
    id: 587,
    parent: 20,
    region: "НИЖНІ СІРОГОЗИ",
  },
  {
    id: 576,
    parent: 20,
    region: "НОВА КАХОВКА",
  },
  {
    id: 588,
    parent: 20,
    region: "НОВОВОРОНЦОВКА",
  },
  {
    id: 589,
    parent: 20,
    region: "НОВОТРОЇЦЬКЕ",
  },
  {
    id: 591,
    parent: 20,
    region: "ОЛЕШКИ",
  },
  {
    id: 590,
    parent: 20,
    region: "СКАДОВСЬК",
  },
  {
    id: 577,
    parent: 20,
    region: "ТАВРІЙСЬК",
  },
  {
    id: 573,
    parent: 20,
    region: "ХЕРСОН",
  },
  {
    id: 592,
    parent: 20,
    region: "ЧАПЛИНКА",
  },
  {
    id: 599,
    parent: 21,
    region: "БІЛОГІР'Я",
  },
  {
    id: 600,
    parent: 21,
    region: "ВІНЬКІВЦІ",
  },
  {
    id: 601,
    parent: 21,
    region: "ВОЛОЧИСЬК",
  },
  {
    id: 602,
    parent: 21,
    region: "ДЕРАЖНЯ",
  },
  {
    id: 603,
    parent: 21,
    region: "ДУНАЇВЦІ",
  },
  {
    id: 604,
    parent: 21,
    region: "ІЗЯСЛАВ",
  },
  {
    id: 594,
    parent: 21,
    region: "КАМ'ЯНЕЦЬ-ПОДІЛЬСЬКИЙ",
  },
  {
    id: 605,
    parent: 21,
    region: "КРАСИЛІВ",
  },
  {
    id: 606,
    parent: 21,
    region: "ЛЕТИЧІВ",
  },
  {
    id: 595,
    parent: 21,
    region: "НЕТІШИН",
  },
  {
    id: 607,
    parent: 21,
    region: "НОВА УШИЦЯ",
  },
  {
    id: 608,
    parent: 21,
    region: "ПОЛОННЕ",
  },
  {
    id: 596,
    parent: 21,
    region: "СЛАВУТА",
  },
  {
    id: 609,
    parent: 21,
    region: "СТАРА СИНЯВА",
  },
  {
    id: 598,
    parent: 21,
    region: "СТАРОКОСТЯНТИНІВ",
  },
  {
    id: 610,
    parent: 21,
    region: "ТЕОФІПОЛЬ",
  },
  {
    id: 593,
    parent: 21,
    region: "ХМЕЛЬНИЦЬКИЙ",
  },
  {
    id: 611,
    parent: 21,
    region: "ЧЕМЕРІВЦІ",
  },
  {
    id: 597,
    parent: 21,
    region: "ШЕПЕТІВКА",
  },
  {
    id: 612,
    parent: 21,
    region: "ЯРМОЛИНЦІ",
  },
  {
    id: 614,
    parent: 22,
    region: "ВАТУТІНЕ",
  },
  {
    id: 619,
    parent: 22,
    region: "ГОРОДИЩЕ",
  },
  {
    id: 620,
    parent: 22,
    region: "ДРАБІВ",
  },
  {
    id: 621,
    parent: 22,
    region: "ЖАШКІВ",
  },
  {
    id: 622,
    parent: 22,
    region: "ЗВЕНИГОРОДКА",
  },
  {
    id: 616,
    parent: 22,
    region: "ЗОЛОТОНОША",
  },
  {
    id: 623,
    parent: 22,
    region: "КАМ'ЯНКА",
  },
  {
    id: 615,
    parent: 22,
    region: "КАНІВ",
  },
  {
    id: 624,
    parent: 22,
    region: "КАТЕРИНОПІЛЬ",
  },
  {
    id: 625,
    parent: 22,
    region: "КОРСУНЬ-ШЕВЧЕНКІВСЬКИЙ",
  },
  {
    id: 626,
    parent: 22,
    region: "ЛИСЯНКА",
  },
  {
    id: 627,
    parent: 22,
    region: "МАНЬКІВКА",
  },
  {
    id: 628,
    parent: 22,
    region: "МОНАСТИРИЩЕ",
  },
  {
    id: 617,
    parent: 22,
    region: "СМІЛА",
  },
  {
    id: 629,
    parent: 22,
    region: "ТАЛЬНЕ",
  },
  {
    id: 618,
    parent: 22,
    region: "УМАНЬ",
  },
  {
    id: 630,
    parent: 22,
    region: "ХРИСТИНІВКА",
  },
  {
    id: 613,
    parent: 22,
    region: "ЧЕРКАСИ",
  },
  {
    id: 632,
    parent: 22,
    region: "ЧИГИРИН",
  },
  {
    id: 631,
    parent: 22,
    region: "ЧОРНОБАЙ",
  },
  {
    id: 633,
    parent: 22,
    region: "ШПОЛА",
  },
  {
    id: 636,
    parent: 23,
    region: "ВАШКІВЦІ",
  },
  {
    id: 635,
    parent: 23,
    region: "ВИЖНИЦЯ",
  },
  {
    id: 637,
    parent: 23,
    region: "ГЕРЦА",
  },
  {
    id: 638,
    parent: 23,
    region: "ГЛИБОКА",
  },
  {
    id: 639,
    parent: 23,
    region: "ЗАСТАВНА",
  },
  {
    id: 640,
    parent: 23,
    region: "КЕЛЬМЕНЦІ",
  },
  {
    id: 641,
    parent: 23,
    region: "КІЦМАНЬ",
  },
  {
    id: 634,
    parent: 23,
    region: "НОВОДНІСТРОВСЬК",
  },
  {
    id: 642,
    parent: 23,
    region: "НОВОСЕЛИЦЯ",
  },
  {
    id: 643,
    parent: 23,
    region: "ПУТИЛА",
  },
  {
    id: 644,
    parent: 23,
    region: "СОКИРЯНИ",
  },
  {
    id: 645,
    parent: 23,
    region: "СТОРОЖИНЕЦЬ",
  },
  {
    id: 646,
    parent: 23,
    region: "ХОТИН",
  },
  {
    id: 777,
    parent: 23,
    region: "ЧЕРНІВЦІ",
  },
  {
    id: 652,
    parent: 24,
    region: "БАТУРИН",
  },
  {
    id: 651,
    parent: 24,
    region: "БАХМАЧ",
  },
  {
    id: 653,
    parent: 24,
    region: "БОБРОВИЦЯ",
  },
  {
    id: 654,
    parent: 24,
    region: "БОРЗНА",
  },
  {
    id: 655,
    parent: 24,
    region: "ВАРВА",
  },
  {
    id: 656,
    parent: 24,
    region: "ГОРОДНЯ",
  },
  {
    id: 657,
    parent: 24,
    region: "ІЧНЯ",
  },
  {
    id: 659,
    parent: 24,
    region: "КОРОП",
  },
  {
    id: 660,
    parent: 24,
    region: "КОРЮКІВКА",
  },
  {
    id: 661,
    parent: 24,
    region: "КУЛИКІВКА",
  },
  {
    id: 664,
    parent: 24,
    region: "ЛАДАН",
  },
  {
    id: 662,
    parent: 24,
    region: "МЕНА",
  },
  {
    id: 648,
    parent: 24,
    region: "НІЖИН",
  },
  {
    id: 649,
    parent: 24,
    region: "НОВГОРОД-СІВЕРСЬКИЙ",
  },
  {
    id: 663,
    parent: 24,
    region: "НОСІВКА",
  },
  {
    id: 658,
    parent: 24,
    region: "ОСТЕР",
  },
  {
    id: 650,
    parent: 24,
    region: "ПРИЛУКИ",
  },
  {
    id: 665,
    parent: 24,
    region: "РІПКИ",
  },
  {
    id: 669,
    parent: 24,
    region: "СНОВСЬК",
  },
  {
    id: 666,
    parent: 24,
    region: "СОСНИЦЯ",
  },
  {
    id: 667,
    parent: 24,
    region: "СРІБНЕ",
  },
  {
    id: 668,
    parent: 24,
    region: "ТАЛАЛАЇВКА",
  },
  {
    id: 647,
    parent: 24,
    region: "ЧЕРНІГІВ",
  },
  {
    id: 0,
    parent: null,
    region: "АВТОНОМНА РЕСПУБЛІКА КРИМ",
  },
  {
    id: 1,
    parent: null,
    region: "ВІННИЦЬКА ОБЛАСТЬ",
  },
  {
    id: 2,
    parent: null,
    region: "ВОЛИНСЬКА ОБЛАСТЬ",
  },
  {
    id: 3,
    parent: null,
    region: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ",
  },
  {
    id: 4,
    parent: null,
    region: "ДОНЕЦЬКА ОБЛАСТЬ",
  },
  {
    id: 5,
    parent: null,
    region: "ЖИТОМИРСЬКА ОБЛАСТЬ",
  },
  {
    id: 6,
    parent: null,
    region: "ЗАКАРПАТСЬКА ОБЛАСТЬ",
  },
  {
    id: 7,
    parent: null,
    region: "ЗАПОРІЗЬКА ОБЛАСТЬ",
  },
  {
    id: 8,
    parent: null,
    region: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ",
  },
  {
    id: 9,
    parent: null,
    region: "КИЇВСЬКА ОБЛАСТЬ",
  },
  {
    id: 10,
    parent: null,
    region: "КІРОВОГРАДСЬКА ОБЛАСТЬ",
  },
  {
    id: 11,
    parent: null,
    region: "ЛУГАНСЬКА ОБЛАСТЬ",
  },
  {
    id: 12,
    parent: null,
    region: "ЛЬВІВСЬКА ОБЛАСТЬ",
  },
  {
    id: 13,
    parent: null,
    region: "МИКОЛАЇВСЬКА ОБЛАСТЬ",
  },
  {
    id: 14,
    parent: null,
    region: "ОДЕСЬКА ОБЛАСТЬ",
  },
  {
    id: 15,
    parent: null,
    region: "ПОЛТАВСЬКА ОБЛАСТЬ",
  },
  {
    id: 16,
    parent: null,
    region: "РІВНЕНСЬКА ОБЛАСТЬ",
  },
  {
    id: 17,
    parent: null,
    region: "СУМСЬКА ОБЛАСТЬ",
  },
  {
    id: 18,
    parent: null,
    region: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ",
  },
  {
    id: 19,
    parent: null,
    region: "ХАРКІВСЬКА ОБЛАСТЬ",
  },
  {
    id: 20,
    parent: null,
    region: "ХЕРСОНСЬКА ОБЛАСТЬ",
  },
  {
    id: 21,
    parent: null,
    region: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ",
  },
  {
    id: 22,
    parent: null,
    region: "ЧЕРКАСЬКА ОБЛАСТЬ",
  },
  {
    id: 23,
    parent: null,
    region: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ",
  },
  {
    id: 24,
    parent: null,
    region: "ЧЕРНІГІВСЬКА ОБЛАСТЬ",
  },
];

export { cities };
