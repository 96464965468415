import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import img_preloader from "./img/preloader.gif";
import img_logo from "./img/logo_blue.png";
import img_logo_small from "./img/logo_small.png";
import img_gear from "./img/gear2.png";
import img_speedometer1 from "./img/speed1.png";
import img_speedometer2 from "./img/speed2.png";
import img_speedometer3 from "./img/speed3.png";
import img_speedometer4 from "./img/speed4.png";
import img_road_1 from "./img/road1.png";
import img_road_2 from "./img/road2.png";
import img_road_3 from "./img/road3.png";
import img_relief1 from "./img/relief1.png";
import img_relief2 from "./img/relief2.png";
import img_relief3 from "./img/relief3.png";
import img_telegram from "./img/icon_telegram.png";
import img_viber from "./img/icon_viber.png";
import img_phone from "./img/icon_phone.png";
import img_fb from "./img/icon_fb.png";
import img_heart2 from "./img/heart02.png";
import img_heart1 from "./img/heart01.png";
import img_heart3 from "./img/heart03.png";
import img_heart4 from "./img/heart04.png";

import {
  BsExclamationCircleFill,
  BsXCircle,
  BsFillInfoCircleFill,
  BsCheckCircle,
  BsCheck,
} from "react-icons/bs";
import { MdDirectionsBike } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import { BiLink } from "react-icons/bi";

//https://react-icons.github.io/react-icons/
// https://react-google-maps-api-docs.netlify.app/#section-introduction - react google maps api
// https://console.cloud.google.com/apis/credentials - google api settings
// https://positionstack.com/ - geodata API
// https://latitude.to/ - convert coords and location info
// https://www.gps-coordinates.net/ - convert coords

// ============= Change before deploy: ==========================================================

const API = "https://n4r.com.ua/api/";
// const API = "http://bike.api/";
// !!!!!!!============ change api/.htaccess after deploy backend (CORS)!
// !!!!!!!============ don`t rewrite & delete api/uploads, api/kml, api/app/config/database

// ==============================================================================================

// const googleMapApiKey = "AIzaSyAJnBy8kAR5LoTbitKbhZqnTcYzTcMYAjk"; // bike.explorer.org.ua
const googleMapApiKey = "AIzaSyAQ1gpqop3KnkCPlX6WtxpFMY-9fEM-Czc"; // n4r.com.ua
// const googleMapApiKey = "AIzaSyBrrB-zRdIgwNAYlWXxmlZqnvNugabNX84"; // localhost
const UPLOADS = "uploads/"; // uploaded img path
const reliefArr = ["", "Рівнина", "Пагорби", "Гори"];
const paceArr = ["", "< 20", "20-25", "25-30", "> 30"];
const difficultyArr = ["", "Легко", "Помірно", "Інтенсивно", "Важко"];
const contactTypeArr = ["", "Телефон", "Viber", "Telegram"];
const eventStatusArr = ["", "Актуально", "Завершено", "Скасовано"];

//================================ functions: ==========
const degreesToRadians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

const getGpsDistance = (lat1, lng1, lat2, lng2) => {
  let earthRadiusKm = 6371;

  let dLat = degreesToRadians(lat2 - lat1);
  let dLng = degreesToRadians(lng2 - lng1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c;
};

const getDaysWord = (days) => {
  if ((days > 10) & (days < 20)) return "днів";
  if (days % 10 === 1) return "день";
  if (days % 10 > 4 || days % 10 === 0) return "днів";
  return "дні";
};

const testNumber = (number, max = 9999, min = 1) => {
  // returns true if {number} is natural number and between min & max
  const reg = new RegExp(/^[0-9]+$/);
  return (reg.test(number) && number <= max && number >= min) || !number;
};

const checkLogin = async (action) => {
  // get is user login and callback {action} function with arg, contains login result
  if (!localStorage.getItem("session")) {
    await action(false);
    return;
  }
  let data = new FormData();
  data.append("session", localStorage.getItem("session"));
  try {
    let res = await axios.post(API + "get_user_data", data);
    let login = res.data.id ? true : false;
    await action(login);
  } catch (err) {
    console.log(err);
  }
};

const getRegion = (cityId, ukraine) => {
  //returns region object by id
  let res = { id: -1, parent: null, region: null };
  if (!ukraine) return res;

  let find = ukraine.find((i) => {
    return +i.id === +cityId;
  });

  return find ? find : res;
};

const LocationSelect = (props) => {
  const [region, setRegion] = useState(
    getRegion(props.city, props.ukraine).parent
  );

  const City = (props) => {
    // city select
    if (!props.ukraine) return null;
    let arr = props.ukraine.map((i, n) => {
      if (
        (+i.parent === +props.region || +props.region === -1) &&
        i.parent !== null
      ) {
        return (
          <option value={i.id} key={i.id}>
            {i.region}
          </option>
        );
      } else return null;
    });
    return (
      <select
        onChange={(e) => {
          props.setCity(e.target.value);
          if (+e.target.value !== -1)
            props.setRegion(getRegion(e.target.value, props.ukraine).parent);
        }}
        value={props.city}
        className="form-select"
      >
        <option value={-1}>- Оберіть місто -</option>
        {arr}
      </select>
    );
  };

  const Region = (props) => {
    if (!props.ukraine) return null;

    const arr = props.ukraine.map((i, n) => {
      const res =
        i.parent === null ? (
          <option value={i.id} key={i.id}>
            {i.region}
          </option>
        ) : null;
      return res;
    });
    return (
      <select
        onChange={(e) => {
          props.setRegion(e.target.value);
          props.setCity(-1);
        }}
        value={region}
        className="form-select"
      >
        <option value={-1}>- Оберіть область -</option>
        {arr}
      </select>
    );
  };
  return (
    <div>
      <Region
        region={region}
        setRegion={setRegion}
        ukraine={props.ukraine}
        setCity={props.setCity}
      />
      <City
        city={props.city}
        setCity={props.setCity}
        region={region}
        setRegion={setRegion}
        ukraine={props.ukraine}
      />
    </div>
  );
};

const loadUserData = async (setUser) => {
  // load user data from database
  let user;
  if (localStorage.getItem("session")) {
    try {
      let data = new FormData();
      data.append("session", localStorage.getItem("session"));
      user = await axios.post(API + "get_user_data", data);
      if (user.data) {
        setUser({
          id: user.data.id,
          name: user.data.name,
          img: user.data.img,
          city: user.data.city,
          about: user.data.about,
          login: true,
          contact: user.data.contacts,
          contactType: user.data.contacts_type,
        });
      } else setUser({ id: null, login: false });
    } catch (err) {
      console.log(err);
    }
  } else setUser({ id: null, login: false });
};

//========================= components: =====================

const StatusError = () => {
  return (
    <span>
      <BsExclamationCircleFill className="status-message status-error" />
    </span>
  );
};
const StatusOk = () => {
  return (
    <span>
      <BsCheckCircle className="status-message status-ok" />
    </span>
  );
};
const StatusInfo = () => {
  return (
    <span>
      <BsFillInfoCircleFill className="status-message status-info" />
    </span>
  );
};
const StatusWarning = () => {
  return (
    <span>
      <BsExclamationCircleFill className="status-message status-warning" />
    </span>
  );
};

const StatusLine = (props) => {
  //props.message: message text
  //props.status: status (warning, error, ok, info)
  if (!props.message) return null;
  let status = null;
  switch (props.status) {
    case "error":
      status = <StatusError />;
      break;
    case "warning":
      status = <StatusWarning />;
      break;
    case "ok":
      status = <StatusOk />;
      break;
    case "info":
      status = <StatusInfo />;
      break;
    default:
      status = null;
      break;
  }
  return (
    <div className="status-line">
      {/* {status} */}
      {props.message}
    </div>
  );
};

const Preloader = (props) => {
  // universal preloader component component
  // props.visible: true/false
  return (
    <div className="preloader-line">
      <img
        className="preloader-img"
        src={img_preloader}
        alt=""
        style={{
          display: props.visible === true ? "block" : "none",
        }}
      />
    </div>
  );
};

const Logo = (props) => {
  // universal logo component
  const img = props.small ? img_logo_small : img_logo;
  if (props.click)
    return (
      <div className="logo">
        <Link to="/">
          <img src={img} alt="need for a ride" />
          {/* <span>Need for a ride</span> */}
        </Link>
      </div>
    );

  return (
    <div className="logo">
      <img src={img} alt="need for a ride" />
      {/* <span>Need for a ride</span> */}
    </div>
  );
};

const WindowCloseBtn = (props) => {
  //universal close button component
  //props.onClick - function
  return (
    <div
      className="nav-window-close"
      onClick={() => {
        props.onClick();
      }}
    >
      <BsXCircle />
    </div>
  );
};
WindowCloseBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const DefaultRadioItem = (props) => {
  // radio button item component for use in the DefaultRadioGroup component
  const css = props.active ? "active" : "no-active";
  const img = props.active ? (
    <img className="icon " src={img_gear} alt="" />
  ) : (
    <div className="icon "></div>
  );

  return (
    <div className={css} onClick={() => props.onClick(props.value)}>
      {img}
      <span>{props.text}</span>
    </div>
  );
};

const DefaultRadioGroup = (props) => {
  //universal radio buttons group component
  const [items, setItems] = useState([]);
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    if (!props.items) return;
    let items = [];
    props.items.forEach((i, n) => {
      if (n > 0)
        items.push(
          <li id={n} key={`key${n}`}>
            <DefaultRadioItem
              active={n === value}
              text={i}
              value={n}
              onClick={(val) => {
                setValue(val);
                props.set(val);
              }}
            />
          </li>
        );
    });
    setItems(items);
  }, [value]);

  return (
    <div className="default-radio-group">
      <h3 className="default-radio-group-title">{props.title}</h3>
      <ul className="default-radio-group-body">{items}</ul>
    </div>
  );
};

const EventProperties = (props) => {
  return (
    <div className="event-item-body">
      <RouteComponent asphalt={props.item.asphalt} />
      <SpeedComponent pace={props.item.pace} />
      <ReliefComponent value={props.item.relief} title={true} />
      <EventDifficulty level={props.item.difficulty} />
    </div>
  );
};

const RouteComponent = (props) => {
  // universal road surface component
  const asphalt = +props.asphalt;
  let roadImg = img_road_2;
  if (asphalt <= 10) roadImg = img_road_1;
  if (asphalt >= 90) roadImg = img_road_3;
  const roadTitle = asphalt >= 50 ? "Асфальт: " : "Грунт: ";
  const roadValue = asphalt >= 50 ? props.asphalt : 100 - asphalt;

  return (
    <div className="event-item-body-property">
      <div className="image">{<img src={roadImg} alt="need for a ride" />}</div>
      <div className="title">
        {roadTitle}&nbsp;{roadValue}%
      </div>
    </div>
  );
};

//---------------------------------

const EventDifficulty = (props) => {
  // universal difficulty level component
  let img;
  const level = Math.floor(+props.level);
  switch (level) {
    case 1:
      img = img_heart1;
      break;
    case 2:
      img = img_heart2;
      break;
    case 3:
      img = img_heart3;
      break;
    case 4:
      img = img_heart4;
      break;
    default:
  }
  const text = difficultyArr[level];
  const style = props.title === false ? { display: "none" } : null;
  if (!level || level === 0) return null;
  return (
    <div className="event-item-body-property">
      <div className="image">
        <img src={img} alt="" />
      </div>
      <div className="title" style={style}>
        {text}
      </div>
    </div>
  );
};

//---------------------------------

const SpeedComponent = (props) => {
  // universal speed with scale component
  let src;
  switch (+props.pace) {
    case 2:
      src = img_speedometer2;
      break;
    case 1:
      src = img_speedometer1;
      break;
    case 3:
      src = img_speedometer3;
      break;
    case 4:
      src = img_speedometer4;
      break;
    default:
      break;
  }
  return (
    <div className="event-item-body-property">
      <div className="image">
        <img src={src} alt="" />
      </div>
      <div className="title">
        {paceArr[props.pace]}
        &nbsp;
        {"км/г"}
      </div>
    </div>
  );
};

//---------------------------------

const ReliefComponent = (props) => {
  // universal relief img component
  const img = [null, img_relief1, img_relief2, img_relief3];
  const text = props.title ? (
    <div className="title">{reliefArr[props.value]}</div>
  ) : null;
  return (
    <div className="event-item-body-property">
      <div className="image">
        <img src={img[props.value]} alt="" />
      </div>
      <div className="title">{text}</div>
    </div>
  );
};

//---------------------------------
const ModalWindow = (props) => {
  // modal window universal component
  // props.visible
  // props.body - jsx component
  // props.title
  // props.close - action on WindowCloseBtn click
  // props.small - small window
  // props.large - large window
  if (!props.visible) return null;
  const css = props.small
    ? " small-window"
    : props.large
    ? " large-window"
    : "";
  const closeBtn = props.close ? (
    <WindowCloseBtn onClick={props.close} />
  ) : null;
  return (
    <div>
      <div className="modal-pad"></div>
      <div className={"modal-window" + css}>
        <div className="modal-window-header">
          <div className="modal-window-title">{props.title}</div>
          {closeBtn}
        </div>
        <div className="modal-window-body">{props.body}</div>
        <div className="modal-window-footer">{props.footer}</div>
      </div>
    </div>
  );
};

//-----------------------------------
const getUserContactImg = (type) => {
  const img =
    type === 1
      ? img_phone
      : type === 2
      ? img_viber
      : type === 3
      ? img_telegram
      : null;
  return img ? <img src={img} alt="" /> : null;
};

const UserContact = (props) => {
  if (!props.visible) return null;
  if (!props.type || !props.contact)
    return (
      <div>
        Щоб користувачі могли з вами зв'язатись, зазначте свої контакти у
        профілі користувача!
      </div>
    );
  return (
    <div className="user-contact">
      <span> Мої контакти:</span>
      {getUserContactImg(props.type)}
      <span>{props.contact}</span>
    </div>
  );
};
const UserContactView = (props) => {
  if (!props.visible) return null;
  if (!props.registered) return null;
  if (!props.type || !props.contact) return null;
  return (
    <div className="user-contact">
      <span> Зв'язок з організатором:</span>
      {getUserContactImg(props.type)}
      <span>{props.contact}</span>
    </div>
  );
};

const CheckBox = (props) => {
  return (
    <div className="checkbox" onClick={props.onClick}>
      <span style={{ opacity: props.checked ? 1 : 0 }}>
        <BsCheck />
      </span>
    </div>
  );
};

const ShareFb = (props) => {
  // share to Facebook button
  // props: url, text
  const url =
    "https://www.facebook.com/sharer/sharer.php?u=" +
    props.url +
    "%2F&amp;src=sdkpreparse";
  return (
    <div className="share-fb-button">
      <a target="blank" rel="nofollow" href={url}>
        <img src={img_fb} alt="" />
        {props.text}
      </a>
    </div>
  );
};

const CopyLink = () => {
  const link = document.location.href;
  return (
    <CopyToClipboard text={link} className="copy-link-button">
      <span>
        <BiLink className="icon" />
        Копіювати посилання
      </span>
    </CopyToClipboard>
  );
};

export {
  ModalWindow,
  testNumber,
  StatusLine,
  StatusWarning,
  StatusOk,
  StatusInfo,
  StatusError,
  Preloader,
  API,
  UPLOADS,
  checkLogin,
  WindowCloseBtn,
  Logo,
  DefaultRadioItem,
  DefaultRadioGroup,
  RouteComponent,
  paceArr,
  reliefArr,
  difficultyArr,
  EventDifficulty,
  ReliefComponent,
  loadUserData,
  getRegion,
  LocationSelect,
  contactTypeArr,
  UserContact,
  UserContactView,
  CheckBox,
  ShareFb,
  CopyLink,
  getDaysWord,
  eventStatusArr,
  googleMapApiKey,
  getGpsDistance,
  EventProperties,
};
