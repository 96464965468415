// components for event edit / view / create form

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/event_form.css";
import "./css/common.css";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// https://react-google-maps-api-docs.netlify.app/#section-introduction - react google maps api
// https://console.cloud.google.com/apis/credentials - google api settings
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Marker, KmlLayer } from "@react-google-maps/api";
import {
  Logo,
  WindowCloseBtn,
  DefaultRadioGroup,
  paceArr,
  reliefArr,
  getRegion,
  LocationSelect,
  API,
  UPLOADS,
  testNumber,
  ModalWindow,
  getDaysWord,
  Preloader,
  StatusLine,
  eventStatusArr,
  difficultyArr,
} from "./common.js";
import { IoLocation } from "react-icons/io5";
import { BsBuilding } from "react-icons/bs";
import { GiCampingTent } from "react-icons/gi";
import { IoCaretForward } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import { BsAlarm } from "react-icons/bs";
import { FcOk, FcCancel } from "react-icons/fc";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoLockClosed } from "react-icons/io5";
import img_noAvatar from "./img/profile.png";
import img_map from "./img/map.png";
import img_track from "./img/track.png";
import img_track_upload from "./img/kml.png";
import img_track_delete from "./img/kml_del.png";
import img_gear from "./img/gear.png";
import { googleMapApiKey } from "./common.js";

const EventFormStatus = (props) => {
  let list = [];
  eventStatusArr.forEach((i, n) => {
    if (n > 0)
      list.push(
        <option value={n} key={n}>
          {i}
        </option>
      );
  });
  return (
    <select
      onChange={(e) => {
        props.setValue(e.target.value);
      }}
      className="form-select event-form-select"
      value={props.value}
    >
      {list}
    </select>
  );
};

const EventFormTrackEdit = (props) => {
  const trackUpload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("event", props.event);
    setPreloaderStatus(true);
    try {
      const res = await axios.post(API + "track_upload", data);
      setTimeout(() => {
        setPreloaderStatus(false);
      }, 500);
      if (res.data.error === 1) {
        setStatusType("warning");
        setStatusMessage("Розмір KML треку не мусить перевищувати 500 Кбайт!");
        setTimeout(() => setStatusMessage(""), 5000);
        return;
      }
      if (res.data.error === 2) {
        setStatusType("warning");
        setStatusMessage(
          "Помилка завантеження KML файлу. Повторіть спробу або завантажте інший трек"
        );
        setTimeout(() => setStatusMessage(""), 5000);
        return;
      }
      if (res.data.error === 0) {
        setStatusType("ok");
        setStatusMessage("Трек успішно завантажений");
        setTimeout(() => {
          props.setTrack(res.data.file);
          // setStatusMessage("");
        }, 2000);
        return;
      }
    } catch (err) {
      console.log("Error track upload ", err);
      setPreloaderStatus(false);
      return { error: true };
    }
  };

  const [preloaderStatus, setPreloaderStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState();
  const action = props.track ? (
    <div
      className="event-form-map"
      onClick={() => {
        props.setTrack("");
        setStatusType("ok");
        setStatusMessage("Трек видалено. Збережіть зміни.");
        setTimeout(() => setStatusMessage(""), 3000);
      }}
    >
      <span>Видалити трек</span>
      <img src={img_track_delete} alt="need for a ride track" />
    </div>
  ) : (
    <div>
      <div className="event-form-map">
        <label htmlFor="kml_file_download">
          <span>Завантажити трек KML</span>
          <img src={img_track_upload} alt="kml" />
        </label>
        <input
          type="file"
          accept=".kml"
          id="kml_file_download"
          onChange={(e) => {
            trackUpload(e.target.files[0]);
          }}
        />
      </div>
      <div className="separator"></div>
      <Preloader visible={preloaderStatus} />
      <StatusLine message={statusMessage} status={statusType} />
    </div>
  );
  return <div>{action}</div>;
};

const Track = (props) => {
  const mapContainerStyle = {
    height: "250",
    width: "450",
  };
  return (
    <ModalWindow
      visible={props.visible}
      close={props.close}
      title={"Маршрут"}
      body={
        <div className="google-object">
          <LoadScript googleMapsApiKey={googleMapApiKey}>
            <GoogleMap
              id="kml-layer"
              mapContainerStyle={mapContainerStyle}
              zoom={11}
            >
              <KmlLayer
                url={API + "kml/" + props.track}
                onStatusChanged={() => {}}
              />
            </GoogleMap>
          </LoadScript>
        </div>
      }
    />
  );
};

const EventFormTrack = (props) => {
  const [trackVisible, setTrackVisible] = useState(false);
  const closeTrack = () => setTrackVisible(false);
  if (props.track)
    return (
      <div>
        <div
          className="event-form-map"
          onClick={() => {
            setTrackVisible(true);
          }}
        >
          <span>Переглянути трек на мапі</span>
          <img src={img_track} alt="need for a ride track" />
        </div>
        <Track visible={trackVisible} close={closeTrack} track={props.track} />
      </div>
    );
  return null;
};

const EventFormMap = (props) => {
  const [mapVisible, setMapVisible] = useState(false);

  if (!props.edit) {
    if (!+props.position.lat && !+props.position.lng) return null;
  }
  const Map = (props) => {
    const containerStyle = {
      width: "400px",
      height: "300px",
    };

    let center = {
      lat: 50.45513368945218,
      lng: 30.53013956546783,
    };

    let position = {
      lat: 50.45513368945218,
      lng: 30.53013956546783,
    };
    if (props.position && +props.position.lat && +props.position.lng) {
      position = { lat: +props.position.lat, lng: +props.position.lng };
      center = { lat: +props.position.lat, lng: +props.position.lng };
    }

    if (!props.visible) return null;
    const confirmButton = props.edit ? (
      <Button
        color={"primary"}
        onClick={() => {
          props.setValue(position);
          props.close();
        }}
      >
        Підтвердити
      </Button>
    ) : null;

    return (
      <ModalWindow
        visible={props.visible}
        close={props.close}
        title={"Точка старту"}
        large={true}
        body={
          <div className="google-object">
            <LoadScript googleMapsApiKey={googleMapApiKey}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
              >
                <Marker
                  position={position}
                  draggable={props.edit}
                  onDrag={(marker) => {
                    if (!props.edit) return;
                    position = {
                      lat: marker.latLng.lat(),
                      lng: marker.latLng.lng(),
                    };
                  }}
                />
              </GoogleMap>
            </LoadScript>
            {confirmButton}
          </div>
        }
      />
    );
  };
  const closeMap = () => setMapVisible(false);
  if (props.edit)
    return (
      <div>
        <div
          className="event-form-map"
          onClick={() => {
            setMapVisible(true);
          }}
        >
          <span>Обрати точку старту на мапі</span>
          <img src={img_map} alt="need for a ride map" />
        </div>
        <Map
          edit={true}
          position={props.position}
          visible={mapVisible}
          close={closeMap}
          setValue={props.setValue}
        />
      </div>
    );
  if (props.position.lat && props.position.lng) {
    return (
      <div>
        <div
          className="event-form-map"
          onClick={() => {
            setMapVisible(true);
          }}
        >
          <span>Переглянути точку старту на мапі:</span>
          <img src={img_map} alt="need for a ride map" />
        </div>
        <Map
          edit={false}
          position={props.position}
          visible={mapVisible}
          close={closeMap}
        />
      </div>
    );
  } else return null;
};

//-----------------------------------------------
const EventFormHeader = (props) => {
  // form 'edit & view event' header
  if (props.editMode) {
    return (
      <div className="event-form-header">
        <Logo small={true} />
        <Link
          to={props.link}
          onClick={(e) => {
            if (props.changes) {
              e.preventDefault();
              props.show(true);
            }
          }}
        >
          <WindowCloseBtn onClick={() => {}} />
        </Link>
      </div>
    );
  } else
    return (
      <div className="event-form-header">
        <div className="distance">{props.distance}&nbsp;км</div>
        <div className="name">{props.event}</div>
        <div className="close">
          <Link
            to={props.link}
            onClick={(e) => {
              if (props.changes) {
                e.preventDefault();
                props.show(true);
              }
            }}
          >
            <WindowCloseBtn onClick={() => {}} />
          </Link>
        </div>
      </div>
    );
};

const EventFormName = (props) => {
  // eventname change component for edit event form
  const placeholder =
    props.type === "/ride"
      ? "Назва покатеньки (від 10 літер)"
      : "Назва походу (від 10 літер)";
  if (props.edit)
    return (
      <input
        className={"event-text-input w100"}
        type="text"
        placeholder={placeholder}
        value={props.value}
        onChange={(e) => {
          props.setValue(e.target.value);
        }}
      />
    );
};

const EventFormAddPeople = (props) => {
  // riders with author quantity change component for edit event form
  if (props.edit)
    return (
      <input
        className={"event-text-input number"}
        type="number"
        value={props.value}
        onChange={(e) => {
          props.setValue(e.target.value);
        }}
      />
    );
};

const EventFormDistance = (props) => {
  // distance change component for edit event form
  if (props.edit)
    return (
      <div className="distance-asphalt">
        <span>Дистанція:&nbsp;</span>
        <input
          className={"event-text-input number "}
          type="number"
          value={props.value}
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
        />
        &nbsp;км
      </div>
    );
};

const EventFormAsphalt = (props) => {
  // road surface change component for edit event form
  if (props.edit)
    return (
      <div className="distance-asphalt">
        <span>Асфальт:&nbsp;</span>
        <input
          className={"event-text-input number "}
          type="number"
          value={props.value}
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
        />
        &nbsp; % &nbsp;
        <div className="asphalt-img"></div>
      </div>
    );
};
//-----------------------------------------------
const EventFormPace = (props) => {
  // pace select component for edit event form
  if (props.edit)
    return (
      <DefaultRadioGroup
        value={props.value}
        items={paceArr}
        title={"Темп (км/год):"}
        set={props.setValue}
      />
    );
};

// const EventFormPaceView = (props) => {
//   // pace component for view event form
//   return <SpeedComponent pace={props.value} title={true} />;
// };

const EventFormRelief = (props) => {
  // relief type select component for edit event form
  if (props.edit)
    return (
      <DefaultRadioGroup
        value={props.value}
        items={reliefArr}
        title={"Рельєф:"}
        set={props.setValue}
      />
    );
};

const calculate = (props) => {
  const relativeDistance = () => {
    // correct distance by dirt/asphalt rate
    // dirt = asphalt * 1.15
    const asphalt = (+props.distance * +props.asphalt) / 100;
    const dirt = (+props.distance * (100 - +props.asphalt)) / 100;
    return asphalt + dirt * 1.15;
  };
  let rate = 1; // start difficulty level
  const paceRate = [0, 0.3, 0.6, 1.5]; // rates for calculate difficulty by pace
  rate += paceRate[+props.pace - 1]; // add rate by pace to base rate
  const durationRate = props.duration < 100 ? +props.duration * 0.02 + 1 : 1; // rate by duration
  rate += (relativeDistance() / +props.duration / 25 - 1) * durationRate; // add distance rate
  rate += (+props.relief - 1) * 0.25; // recalc with relief rate
  // const durationRate = (+props.duration - 1) * 0.05 + 1;
  // rate *= durationRate;
  rate *= (+props.hikeType - 1) * 0.25 + 1; // recalc by hike type
  rate = rate < 1 ? 1 : rate > 4.9 ? 4.9 : rate; // recalc from 1 to 4
  return rate;
};

const EventFormLevelIndicator = (props) => {
  return (
    <div className="event-level-indicator">
      <div
        className="event-level-cover"
        style={{ left: `${props.value * 19.5}%` }}
      >
        <img
          src={img_gear}
          style={{ transform: `rotate(${+props.value * 200}deg)` }}
          alt={"n4r"}
        ></img>
      </div>
    </div>
  );
};

const EventFormLevel = (props) => {
  if (!props.edit) return;
  const rate = calculate(props);
  // props.compare(Math.floor(rate));
  return (
    <div className="event-level">
      <span>Навантаження:</span>&nbsp;
      <span className="event-form-level-value">
        {difficultyArr[Math.floor(rate)]}
      </span>
      <EventFormLevelIndicator value={rate} />
    </div>
  );
};

const EventFormTextarea = (props) => {
  if (props.edit)
    return (
      <div className="event-form-textarea">
        <div className="title">{props.title}</div>
        <textarea
          className="body"
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
          placeholder={props.placeholder}
          rows={props.rows}
        ></textarea>
      </div>
    );
};
//-----------------------------------------------
const EventFormTextareaView = (props) => {
  if (!props.value || props.value == "") return null;
  return (
    <div className="event-form-textarea">
      <div className="title">{props.title}</div>
      <div className="body">{props.value}</div>
    </div>
  );
};
//-----------------------------------------------

const EventFormHikeTypeComponent = (props) => {
  return props.value === 1 ? (
    <span>
      &nbsp;в готелях&nbsp;
      <span className="icon">
        <BsBuilding />
      </span>
    </span>
  ) : (
    <span>
      &nbsp;в наметах&nbsp;
      <span className="icon">
        <GiCampingTent />
      </span>
    </span>
  );
};

const EventFormHikeTypeItem = (props) => {
  const css =
    props.value === props.id
      ? "event-hike-type-item active"
      : "event-hike-type-item";
  return (
    <div className={css} onClick={() => props.setValue(props.id)}>
      <EventFormHikeTypeComponent value={props.id} />
    </div>
  );
};

const EventFormHikeType = (props) => {
  return (
    <div className="event-hike-type-select">
      <EventFormHikeTypeItem
        id={1}
        value={props.value}
        setValue={props.setValue}
        text={"в готелях"}
      />
      <EventFormHikeTypeItem
        id={2}
        value={props.value}
        setValue={props.setValue}
        text={"в наметах"}
      />
    </div>
  );
};
//-----------------------------------------------
const EventFormHike = (props) => {
  if (!props.isHike) return null;
  // if (!props.value || props.value < 2) return null;
  if (props.edit)
    return (
      <div>
        <input
          type="number"
          className="event-text-input number"
          value={props.value}
          onChange={(e) => props.setValue(e.target.value)}
        />
        <span>&nbsp;{getDaysWord(props.value)}&nbsp;</span>
        <EventFormHikeType value={props.value2} setValue={props.setValue2} />
      </div>
    );
};

const EventFormHikeView = (props) => {
  if (!props.value || props.value < 2) return null;
  return (
    <div className="event-view-hike">
      {props.value}&nbsp;{getDaysWord(props.value)}
      <EventFormHikeTypeComponent value={props.value2} />
    </div>
  );
};

const EventFormLocation = (props) => {
  return (
    <LocationSelect
      city={props.city}
      setCity={props.setCity}
      ukraine={props.ukraine}
    />
  );
};

const EventFormLocationView = (props) => {
  if (!props.ukraine.length) return null;
  const city = getRegion(props.region, props.ukraine);
  const cityName = city.region;
  const regionName = city ? getRegion(city.parent, props.ukraine).region : "";
  return (
    <div className="event-form-location-view">
      <span className="icon">
        <IoLocation />
      </span>
      {cityName}
      <span className="region"> ({regionName}) </span>
    </div>
  );
};

const EventFormAuthorView = (props) => {
  let img = props.img ? `${API}${UPLOADS}${props.img}` : img_noAvatar;
  return (
    <div className="event-form-author-view">
      <div className="name">{props.author}</div>
      <div className="img">{<img src={img} alt="Автор покатеньки" />}</div>
    </div>
  );
};

const EventFormDateView = (props) => {
  return (
    <div className="event-form-date-view">
      <div>
        <span className="icon">
          <FaRegCalendarAlt />
        </span>
        <span className="text">{props.date}</span>
      </div>
      <div>
        <span className="icon">
          <BsAlarm />
        </span>
        <span className="text">{props.timeStart}</span>
      </div>
    </div>
  );
};

const EventFormDate = (props) => {
  return (
    <div className="event-date-time">
      <span className="icon">
        <FaRegCalendarAlt />
      </span>
      Старт:
      <input
        className="event-text-input auto-width input"
        type="date"
        min={props.minDate}
        value={props.date}
        onChange={(e) => props.setDate(e.target.value)}
      />
      <span className="icon">
        <BsAlarm />
      </span>
      <input
        className="event-text-input auto-width"
        type="time"
        value={props.timeStart}
        onChange={(e) => props.setTimeStart(e.target.value)}
      />
    </div>
  );
};

const EventFormJoin = (props) => {
  // const [registered, setRegistered] = useState(false);
  const isRegistered = async () => {
    // get response is the user registered in the event
    try {
      let data = new FormData();
      data.append("user", props.user);
      data.append("event", props.event);
      let res = await axios.post(API + "is_registered", data); //load registered users count
      if (res.data) {
        if (res.data.registered) {
          props.setRegistered(true);
        } else props.setRegistered(false);
      }
    } catch (err) {
      console.log(
        "Error get response is the user registered in the event: ",
        err
      );
    }
  };

  useEffect(() => {
    // load current event data
    isRegistered();
  }, []);

  if (!props.user) return null;

  const JoinButton = (props) => {
    if (props.registerDisable)
      return (
        <div className="event-register-disable">
          <span className="icon">
            <IoLockClosed />
          </span>
          Реєстрація закрита
        </div>
      );
    return (
      <div>
        <Button
          color="primary"
          size="lg"
          onClick={() => {
            props.joinAction();
          }}
        >
          Приєднатись <IoCaretForward /> <BsPeopleFill />
        </Button>
      </div>
    );
  };

  const UnjoinButton = (props) => {
    return (
      <div>
        <span>
          Ви зареєстровані <FcOk />
        </span>{" "}
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            props.unJoinAction();
          }}
        >
          Відмовитись <FcCancel />
        </Button>
      </div>
    );
  };

  return !props.registered ? (
    <JoinButton
      joinAction={props.joinAction}
      registerDisable={props.registerDisable}
    />
  ) : (
    <UnjoinButton unJoinAction={props.unJoinAction} />
  );
};

const EventChangeModalFooter = (props) => {
  return (
    <div className="event-buttons-bar center">
      <Button
        color="primary"
        onClick={() => {
          props.hide();
          props.refToButtonsBar.current.scrollIntoView();
          props.submit(true, props.link);
        }}
      >
        Зберегти зміни
      </Button>
      <Link to={props.link}>
        <Button color="secondary" onClick={() => {}}>
          Скасувати зміни
        </Button>
      </Link>
    </div>
  );
};

const EventCriticalChangesModalBody = (props) => {
  let text = (
    <div className="center">
      Ви внесли важливі зміни після того, як користувачі вже зареєструвались до
      цієї події. Змінено:
      <p className="italic">{props.text}</p>
      Користувачів буде повідомлено на email.
    </div>
  );
  return <div className="center">{text}</div>;
};

const EventCriticalChangesModalFooter = (props) => {
  return (
    <div className="event-buttons-bar center">
      <Button
        color="primary"
        onClick={() => {
          props.hide();
          props.refToButtonsBar.current.scrollIntoView();
          props.submit();
        }}
      >
        Зберегти зміни
      </Button>
      <Link to={props.link}>
        <Button color="secondary" onClick={() => {}}>
          Скасувати зміни
        </Button>
      </Link>
      <Button
        color="secondary"
        onClick={() => {
          props.hide();
        }}
      >
        Повернутись
      </Button>
    </div>
  );
};

const EventJoinModalBody = (props) => {
  // props.success - action when new record created
  // body of user join modal
  const [count, setCount] = useState(0);

  const setCountValue = (e) => {
    if (testNumber(e.target.value, 5, 0)) setCount(e.target.value);
  };

  const saveRecord = async (data) => {
    const res = await axios.post(API + "is_registered", data);
    if (res.data.registered) {
      console.log("Already registered");
    } else {
      // create new record in registration table
      const add_people = +count >= 0 && +count <= 5 ? +count : 0;
      data.append("add_people", add_people);
      const resp = await axios.post(API + "user_join", data);
      props.success(resp);
    }
  };

  const submit = (close, link) => {
    let data = new FormData();
    data.append("user", props.user);
    data.append("event", props.event);
    saveRecord(data);
  };

  return (
    <div>
      <form className="event-edit-body">
        <div>Їдуть зі мною:</div>
        <div className="remark">
          (тут можна зазначити кількість незареєстрованих учасників, які поїдуть
          разом з вами)
        </div>
        <input
          type="number"
          value={count}
          onChange={setCountValue}
          className={"event-text-input number"}
        />
        &nbsp;від 0 до 5 людей
        <div className="separator"></div>
        <div>
          <Button
            color="primary"
            size="lg"
            onClick={() => {
              submit();
            }}
          >
            Підтверджую
          </Button>
        </div>
      </form>
    </div>
  );
};

const EventUnJoinModalFooter = (props) => {
  // body of user unjoin modal
  const submit = () => {
    let data = new FormData();
    data.append("user", props.user);
    data.append("event", props.event);
    deleteRecord(data);
  };

  const deleteRecord = async (data) => {
    const res = await axios.post(API + "is_registered", data);
    if (!res.data.registered) {
      console.log("Not registered");
    } else {
      // delete record in registration table
      const resp = await axios.post(API + "user_unjoin", data);
      props.success(resp);
    }
  };

  return (
    <div className="center">
      <Button
        color="primary"
        size="lg"
        onClick={() => {
          submit();
        }}
      >
        Відмовляюсь
      </Button>
    </div>
  );
};

const EventParticipants = (props) => {
  const btnText = props.author ? "Зареєстровано: " : "Учасники:";
  const count = props.author
    ? props.count
    : +props.count + +props.addPeople + 1;
  return (
    <Button color="info" onClick={props.onClick}>
      <BsPeopleFill /> {btnText} {count}
    </Button>
  );
};

const EventRidersList = (props) => {
  // list of users, registered in event component
  let list = [];
  const [items, setItems] = useState([]);
  const Item = (props) => {
    return (
      <div
        className={
          props.author ? "event-riders-list-author" : "event-riders-list-item"
        }
      >
        <div className="event-riders-list-img">
          <img
            src={props.img ? API + UPLOADS + props.img : img_noAvatar}
            alt="n4-riders"
          />
        </div>
        <span>{props.name ? props.name : ""}</span>
        <span>{props.add}</span>
      </div>
    );
  };

  const load = async () => {
    const authorName = props.authorName ? props.authorName : "";
    list.push(
      // add event author to riders list
      <Item
        img={props.authorImg}
        name={authorName + " (організатор)"}
        add={+props.addPeople ? " + " + props.addPeople : null}
        author={true}
        key="author"
      />
    );
    let data = new FormData();
    data.append("event", props.event);
    let res = await axios.post(API + "get_registered_riders", data);
    if (res.data) {
      for (let i of res.data) {
        list.push(
          <Item
            img={i.img}
            name={i.name}
            add={+i.add_people ? " + " + i.add_people : null}
            key={i.user_id}
          />
        );
      }
    }
    if (list) setItems(list);
  };

  useEffect(() => {
    if (!props.visible) return;
    try {
      load();
    } catch (err) {
      console.log("error load riders list! ", err);
    }
  }, [props.visible]);

  if (!props.visible) return null;
  return (
    <div>
      <div className="modal-pad"></div>
      <div className="event-riders-list">
        <div className="event-riders-list-header">
          <div className="event-riders-list-title">Перелік учасників</div>
          <WindowCloseBtn onClick={props.close} />
        </div>
        <div className="event-riders-list-body">{items}</div>
      </div>
    </div>
  );
};

const EventComments = (props) => {
  if (!props.comments) return null;
  let commentsArr = [];
  props.comments.forEach((i, n) => {
    commentsArr.push(
      <li className="event-view-comment" key={n}>
        {i.comment}
      </li>
    );
  });
  return (
    <div className="event-view-comments">
      <ul>{commentsArr}</ul>
    </div>
  );
};

export {
  EventFormHeader,
  EventFormName,
  EventFormDistance,
  EventFormAsphalt,
  EventFormPace,
  EventFormRelief,
  EventFormLevel,
  EventFormTextareaView,
  EventFormTextarea,
  EventFormHikeView,
  EventFormHike,
  EventFormJoin,
  EventParticipants,
  EventFormLocation,
  EventFormLocationView,
  EventFormDateView,
  EventFormDate,
  EventRidersList,
  EventFormAddPeople,
  EventJoinModalBody,
  EventUnJoinModalFooter,
  EventFormMap,
  EventFormTrack,
  EventFormTrackEdit,
  EventFormStatus,
  calculate,
  EventChangeModalFooter,
  EventCriticalChangesModalBody,
  EventCriticalChangesModalFooter,
  EventFormAuthorView,
  EventComments,
};
