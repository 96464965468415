import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./css/common.css";
import "./css/events.css";
import "./css/event_form.css";
import { Button } from "reactstrap";
import { calculate } from "./event_form";
import img_noAvatar from "./img/profile.png";

import {
  testNumber,
  Preloader,
  StatusLine,
  API,
  checkLogin,
  Logo,
  WindowCloseBtn,
  getRegion,
  ModalWindow,
  StatusWarning,
  UserContact,
  UserContactView,
  CheckBox,
  ShareFb,
  CopyLink,
  UPLOADS,
  EventProperties,
} from "./common.js";
import { cities } from "./ukraine.js";
import {
  EventFormHeader,
  EventFormName,
  EventFormDistance,
  EventFormAsphalt,
  EventFormPace,
  EventFormRelief,
  EventFormLevel,
  EventFormTextarea,
  EventFormTextareaView,
  EventFormHikeView,
  EventFormHike,
  EventFormJoin,
  EventParticipants,
  EventFormLocationView,
  EventFormLocation,
  EventFormDateView,
  EventFormDate,
  EventFormAddPeople,
  EventRidersList,
  EventJoinModalBody,
  EventUnJoinModalFooter,
  EventFormMap,
  EventFormTrack,
  EventFormTrackEdit,
  EventFormStatus,
  EventChangeModalFooter,
  EventCriticalChangesModalBody,
  EventCriticalChangesModalFooter,
  EventFormAuthorView,
  EventComments,
} from "./event_form.js";
import { Event } from "./event";

import {
  BsList,
  BsFillPlusCircleFill,
  BsArrowLeft,
  BsHouseDoor,
  BsBoxArrowInRight,
  BsPersonPlusFill,
} from "react-icons/bs";

const EventButtonsBar = (props) => {
  // buttons for event edit mode
  const css = props.visible ? "" : " invisible";
  return (
    <div ref={props.buttonsBarRef} className={"event-buttons-bar" + css}>
      <Button type="button" color="primary" onClick={props.submit}>
        Зберегти зміни
      </Button>
      <a
        href={props.link}
        onClick={(e) => {
          if (props.changes) {
            e.preventDefault();
            props.show(true);
          }
        }}
      >
        <Button type="button" color="secondary">
          Вийти
        </Button>
      </a>
    </div>
  );
};

const EventFormShare = (props) => {
  const link = window.location.href;
  return (
    <div className="event-form-share">
      <ShareFb url={link} text="Поділитись" />
      <CopyLink />
    </div>
  );
};
//========================================================================
//                  EVENT EDIT, CREATE & VIEW FORM
//========================================================================

const EventEdit = (props) => {
  // form for view, edit & create event
  const id = useParams().id; // current event id
  const buttonsBarRef = useRef(null);
  const create = +id === -1 || +id === -2 ? true : false; // create mode: id===-1: ride, id===-2: hike
  let eventData; // current event data, loaded from api
  let userId; // current user id
  // min date value calculate:
  const dateCurrent = new Date();
  const minDate = `${dateCurrent.getFullYear()}-${
    +dateCurrent.getMonth() + 1 < 10
      ? "0" + (+dateCurrent.getMonth() + 1)
      : +dateCurrent.getMonth() + 1
  }-${
    +dateCurrent.getDate() < 10
      ? "0" + dateCurrent.getDate()
      : dateCurrent.getDate()
  }`;

  const [ridersListVisible, setRidersListVisible] = useState(false);
  const [complete, setComplete] = useState(false); // current user id
  const [user, setUser] = useState(null); // current user id
  const [editMode, setEditMode] = useState(null); // edit or view form mode
  const [link, setLink] = useState(null); // link for return to main window (hikes or rides events page)
  const [preloaderStatus, setPreloaderStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusType, setStatusType] = useState("");
  const [buttonsStatus, setButtonsStatus] = useState(true);
  const [event, setEvent] = useState(""); // event name
  const [distance, setDistance] = useState(30);
  const [pace, setPace] = useState(1);
  const [asphalt, setAsphalt] = useState(1);
  const [relief, setRelief] = useState(1);
  const [difficulty, setDifficulty] = useState(1);
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState();
  const [hikeType, setHikeType] = useState(1);
  const [children, setChildren] = useState(0);
  const [region, setRegion] = useState(-1);
  const [place, setPlace] = useState("");
  const [date, setDate] = useState("");
  const [timeMeet, setTimeMeet] = useState("09:00");
  const [timeStart, setTimeStart] = useState("09:15");
  const [take, setTake] = useState("");
  const [addPeople, setAddPeople] = useState(0); // quantity riders with author
  const [eventJoinModalVisible, setEventJoinModalVisible] = useState(false); // user join modal window
  const [eventUnJoinModalVisible, setEventUnJoinModalVisible] = useState(false); // user unjoin modal window
  const [eventSaveModalVisible, setEventSaveModalVisible] = useState(false); // save record modal window
  const [
    eventCriticalChangesModalVisible,
    setEventCriticalChangesModalVisible,
  ] = useState(false); // critical changes modal window visible
  const [criticalChangesText, setCriticalChangesText] = useState("");
  const [author, setAuthor] = useState({});
  const [contactType, setContactType] = useState(0);
  const [contactValue, setContactValue] = useState(0);
  const [contactVisible, setContactVisible] = useState(0);
  const [position, setPosition] = useState({ lat: null, lng: null });
  const [track, setTrack] = useState(null);
  const [registered, setRegistered] = useState(false);
  const [ridersCount, setRidersCount] = useState(0);
  const [status, setStatus] = useState(0);
  const [isHike, setIsHike] = useState();
  const [changes, setChanges] = useState(false);
  const [registerDisable, setRegisterEnable] = useState(0);
  const [comments, setComments] = useState([]);

  const calcProps = {
    // props for calculate difficulty level
    distance: distance,
    asphalt: asphalt,
    pace: pace,
    relief: relief,
    duration: duration,
    hikeType: hikeType,
  };

  // ==================================================================================
  //                                load comments list:
  // ==================================================================================

  const loadComments = async () => {
    let data = new FormData();
    data.append("event_id", eventData.id);
    try {
      const res = await axios.post(API + "get_comments", data);
      setComments(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  // ==================================================================================
  //                         check for critical changes made:
  // ==================================================================================

  const checkCriticalChanges = async () => {
    let data = new FormData();
    data.append("id", id);
    data.append("status", status);
    data.append("difficulty", Math.floor(calculate(calcProps)));
    data.append("region", region);
    data.append("lat", position.lat);
    data.append("lng", position.lng);
    data.append("date", date);
    data.append("time", timeStart);
    try {
      const res = await axios.post(API + "get_critical_changes", data);
      if (res.data.changes && ridersCount) {
        setEventCriticalChangesModalVisible(true);
        setCriticalChangesText(res.data.changes);
      } else save();
    } catch (err) {
      console.log(err);
    }
  };

  // ==================================================================================
  //                                save record:
  // ==================================================================================

  const save = async () => {
    if (localStorage.getItem("session")) {
      // if user login:
      let data = new FormData();
      data.append("session", localStorage.getItem("session"));
      data.append("user_id", user);
      data.append("create_mode", create ? 1 : 0); // for create new record
      data.append("id", id);
      data.append("event", event);
      data.append("difficulty", calculate(calcProps));
      data.append("description", description);
      data.append("distance", distance);
      data.append("pace", pace);
      data.append("asphalt", asphalt);
      data.append("relief", relief);
      data.append("duration", duration);
      data.append("hike_type", hikeType);
      data.append("region", region);
      data.append("place", place);
      data.append("date", date);
      data.append("time_start", timeStart);
      data.append("time_meet", timeMeet);
      data.append("take", take);
      data.append("children", children);
      data.append("add_people", addPeople);
      data.append("start_lat", position.lat);
      data.append("start_lng", position.lng);
      data.append("contact_visible", +contactVisible);
      data.append("track", track);
      data.append("status", +status);
      data.append("is_hike", +isHike);
      data.append("critical_changes", criticalChangesText);
      data.append("register_disable", +registerDisable);
      setButtonsStatus(false);
      setPreloaderStatus(true);
      try {
        const res = await axios.post(API + "save_event", data);
        if (res.data.save) {
          // save record complete:
          setTimeout(() => {
            setStatusType("ok");
            setStatusMessage("Інформація збережена");
            setPreloaderStatus(false);
            window.location.assign(link);
          }, 1000);
        } else {
          // save record not complete (validation failed):
          setStatusType("warning");
          setPreloaderStatus(false);
          switch (+res.data.error) {
            case 1:
              setStatusMessage("Вам необхідно авторизуватись");
              break;
            case 2:
              setStatusMessage("Заповніть поле назва (від 10 літер)");
              break;
            case 3:
              setStatusMessage("Заповніть поле опис маршруту (від 20 літер)");
              break;
            case 4:
              setStatusMessage("Оберіть місце старту (місто)");
              break;
            case 5:
              setStatusMessage("Заповніть поле 'Де збираємось' (від 10 літер)");
              break;
            case 6:
              setStatusMessage("Оберіть дату");
              break;
            case 7:
              setStatusMessage("Тривалість походу мусить складати від 2 днів");
              break;
            default:
              setStatusMessage("Помилка");
          }
          setTimeout(() => {
            setButtonsStatus(true);
            setStatusMessage("");
          }, 4000);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setStatusMessage("Вам необхідно авторизуватись");
      setPreloaderStatus(false);
    }
  };
  // ==================================================================================
  //                                form submit key action:
  // ==================================================================================

  const submit = () => {
    if (+id >= 0) {
      checkCriticalChanges();
    } else save();
  };

  // ==================================================================================
  //                         form fields change handlers
  // ==================================================================================

  const showSaveModalWindow = (show) => setEventSaveModalVisible(show);

  const setEventValue = (val) => {
    val = val.length <= 100 ? val : val.substr(0, 100);
    if (val == null) val = "";
    setEvent(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setDistanceValue = (val) => {
    if (testNumber(val, 1000, 0)) {
      setDistance(val);
      setChanges(true);
      setStatusMessage("");
    }
  };

  const setAsphaltValue = (val) => {
    if (testNumber(val, 100, 0)) {
      setAsphalt(val);
      setChanges(true);
      setStatusMessage("");
    }
  };

  const setAddPeopleValue = (val) => {
    if (testNumber(val, 5, 0)) {
      setAddPeople(val);
      setChanges(true);
      setStatusMessage("");
    }
  };

  const setPaceValue = (val) => {
    setPace(+val);
    setChanges(true);
    setStatusMessage("");
  };

  const setReliefValue = (val) => {
    setRelief(+val);
    setChanges(true);
    setStatusMessage("");
  };

  const setDescriptionValue = (val) => {
    if (!val) val = "";
    if (val.length <= 1000) setDescription(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setDurationValue = (val) => {
    if (testNumber(val, 100, 0)) {
      setDuration(val);
      setChanges(true);
      setStatusMessage("");
    }
  };

  const setHikeTypeValue = (val) => {
    setHikeType(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setPlaceValue = (val) => {
    if (!val) val = "";
    setPlace(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setRegionValue = (val) => {
    setRegion(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setDateValue = (val) => {
    setDate(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setTimeStartValue = (val) => {
    setTimeStart(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setTimeMeetValue = (val) => {
    setTimeMeet(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setTakeValue = (val) => {
    if (!val) val = "";
    setTake(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setPositionValue = (val) => {
    // start position set
    setPosition(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setTrackValue = (val) => {
    // start position set
    setTrack(val);
    if (!val) setChanges(true);
    setStatusMessage("");
  };

  const setStatusValue = (val) => {
    // start position set
    setStatus(val);
    setChanges(true);
    setStatusMessage("");
  };

  const setRegisterEnableValue = (val) => {
    setRegisterEnable(val);
    setChanges(true);
    setStatusMessage("");
  };
  // ==================================================================================
  //                                load registered users list:
  // ==================================================================================

  const loadRegistered = async () => {
    //load registered users count
    try {
      let data = new FormData();
      data.append("id", id);
      let res = await axios.post(API + "get_registered_count", data);
      if (res.data) {
        setRidersCount(+res.data.cnt);
      }
    } catch (err) {
      console.log("Error load registered users count: ", err);
    }
  };
  // ==================================================================================
  //                                load data:
  // ==================================================================================

  const load = async () => {
    if (!create)
      // edit or view, not create mode:
      try {
        // load event data
        let data = new FormData();
        data.append("id", id);
        let res = await axios.post(API + "get_event_data", data); //load event data
        if (res.data) {
          eventData = res.data;
        } else {
          return <div>Помилка. Перезаванжте сторінку</div>;
        }
      } catch (err) {
        console.log("Error load event data: ", err);
      }

    try {
      // load user data. must call after event data loaded
      let data = new FormData();
      data.append("session", localStorage.getItem("session"));
      let getUser = await axios.post(API + "get_user_data", data);
      userId = getUser.data && getUser.data.id ? getUser.data.id : null; // set current user id value
      if (userId) {
        setUser(userId);
        setRegion(getUser.data.city);
        setAuthor({
          authorName: getUser.data.name,
          authorImg: getUser.data.img,
          authorId: getUser.data.id,
        });
      }
    } catch (err) {
      console.log("Error read user data", err);
    }

    if (create) {
      // new event create mode
      setIsHike(+id === -2 ? true : false); // is this event hike
      setDuration(+id === -2 ? 2 : 1);
      setEditMode(true);
      setLink(+id === -2 ? "/hike" : "/ride"); // link for return when close button pressed
      setDate(minDate);
      setComplete(true); // loading finished
    }
    if (eventData && !create) {
      // if open event for view or edit mode && not create mode:
      if (!(+eventData.author_id === +userId)) {
        // if open event form for view - add views count
        let data = new FormData();
        data.append("event_id", eventData.id);
        data.append("views", +eventData.views + 1);
        try {
          const res = await axios.post(API + "add_view", data);
        } catch (err) {
          console.log(err);
        }
      }

      setLink(+eventData.duration > 1 ? "/hike" : "/ride"); // link for return when close button pressed
      setIsHike(+eventData.duration > 1 ? true : false); // is this event hike
      setEvent(eventData.event);
      setDistance(+eventData.distance);
      setAsphalt(+eventData.asphalt);
      setPace(+eventData.pace);
      setRelief(+eventData.relief);
      setDifficulty(+eventData.difficulty);
      setDescription(eventData.description);
      setDuration(+eventData.duration);
      setHikeType(+eventData.hike_type);
      setRegion(+eventData.city_id);
      setChildren(+eventData.children);
      setPlace(eventData.place);
      setDate(eventData.date);
      setTimeStart(eventData.time_start);
      setTimeMeet(eventData.time_meet);
      setTake(eventData.take);
      setAddPeople(eventData.add_people);
      setPosition({ lat: eventData.start_lat, lng: eventData.start_lng });
      setTrack(eventData.track);
      setAuthor({
        authorName: eventData.author_name,
        authorImg: eventData.author_img,
        groupName: eventData.group_name,
        groupImg: eventData.group_img,
        authorId: eventData.author_id,
        groupId: eventData.group_id,
      });
      setContactVisible(+eventData.contact_visible);
      setContactValue(eventData.contacts);
      setContactType(+eventData.contacts_type);

      setEditMode(+eventData.author_id === +userId ? true : false); // edit mode if current user is author of the event
      setComplete(true);
      setStatus(+eventData.status);
      setRegisterEnable(+eventData.register_disable);
    }
  };
  // ==================================================================================
  //                                useEffect:
  // ==================================================================================
  useEffect(() => {
    // load comments frequently
    let interval = setInterval(() => loadComments(), 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // load registered users count
    loadRegistered();
  }, []);

  useEffect(() => {
    // load data
    load();
  }, []);

  // ==================================================================================
  //                        if not registered & attempt to create:
  // ==================================================================================

  if (!complete) return null;
  if (!user && create)
    return (
      <ModalWindow
        visible={true}
        body={
          <span>
            <StatusWarning />
            Щоб створити покатеньку або похід необхідно авторизуватись
          </span>
        }
        title={"Необхідна авторизація!"}
        small={true}
        close={() => window.location.assign(link)}
      />
    );
  // ==================================================================================
  //                                open form for edit:
  // ==================================================================================
  if (editMode === true)
    return (
      <div className="event-form">
        <EventFormHeader
          link={link}
          changes={changes}
          show={showSaveModalWindow}
          editMode={editMode}
        />
        <form action="" className="event-edit-body">
          <ul>
            <li>
              <EventFormName
                edit={editMode}
                value={event}
                setValue={setEventValue}
                type={link}
              />
            </li>
            <li>
              <EventFormHike
                edit={editMode}
                value={duration}
                value2={hikeType}
                setValue={setDurationValue}
                setValue2={setHikeTypeValue}
                isHike={isHike}
              />
            </li>
            <li className="separator"></li>
            <li>
              <EventFormDistance
                edit={editMode}
                value={distance}
                setValue={setDistanceValue}
              />
              <EventFormAsphalt
                edit={editMode}
                value={asphalt}
                setValue={setAsphaltValue}
              />
            </li>
            <li className="separator"></li>
            <li className="pace-relief">
              <div className="pace-relief-element">
                <EventFormPace
                  edit={editMode}
                  value={pace}
                  setValue={setPaceValue}
                />
              </div>
              <div className="pace-relief-element">
                <EventFormRelief
                  edit={editMode}
                  value={relief}
                  setValue={setReliefValue}
                />
              </div>
            </li>
            <li>
              <EventFormLevel
                edit={editMode}
                distance={distance}
                asphalt={asphalt}
                pace={pace}
                relief={relief}
                duration={duration}
                hikeType={hikeType}
              />
            </li>
            <li className="separator"></li>
            <li>
              <EventFormTextarea
                edit={editMode}
                value={description}
                title={"Опис маршруту"}
                setValue={setDescriptionValue}
                placeholder={"Опис маршруту  (від 20 літер)"}
              />
            </li>
            <li>
              <EventFormDate
                edit={editMode}
                date={date}
                timeStart={timeStart}
                timeMeet={timeMeet}
                setDate={setDateValue}
                setTimeMeet={setTimeMeetValue}
                setTimeStart={setTimeStartValue}
                minDate={minDate}
              />
            </li>
            <li className="header">Місце старту:</li>
            <li className="padding">
              <EventFormLocation
                city={region}
                setCity={setRegionValue}
                ukraine={cities}
              />
            </li>
            <li>
              <EventFormMap
                edit={true}
                position={position}
                setValue={setPositionValue}
              />
            </li>
            <li>
              <EventFormTrack track={track} />
              <EventFormTrackEdit
                track={track}
                setTrack={setTrackValue}
                event={id}
              />
            </li>
            <li>
              <EventFormTextarea
                edit={editMode}
                value={place}
                title={"Де збираємось:"}
                setValue={setPlaceValue}
                placeholder={"Де збираємось (від 10 літер)"}
              />
            </li>

            <li>
              <EventFormTextarea
                edit={editMode}
                value={take}
                title={"Що брати з собою:"}
                setValue={setTakeValue}
                placeholder={"Брати з собою:"}
              />
            </li>
            <li className="header">
              Показати мої контакти&nbsp;
              <CheckBox
                checked={contactVisible}
                onClick={() => {
                  setContactVisible(!contactVisible);
                }}
              />
              <span className="small">
                (лише для зареєстрованих в цю поїздку)
              </span>
            </li>
            <li>
              <UserContact
                visible={contactVisible}
                type={contactType}
                contact={contactValue}
              />
            </li>
            <li className="header">
              Їдуть зі мною (незареєстровані учасники):
            </li>
            <li>
              <EventFormAddPeople
                edit={editMode}
                value={addPeople}
                setValue={setAddPeopleValue}
              />
              &nbsp; від 0 до 5 людей
            </li>
            <li className="separator"></li>
            <li className="event-edit-register">
              <EventParticipants
                event={id}
                author={true}
                onClick={() => setRidersListVisible(!ridersListVisible)}
                addPeople={addPeople}
                count={ridersCount}
              />
              <span className="event-edit-register-stop">
                <span>Реєстрація закрита:</span>
                <CheckBox
                  checked={registerDisable}
                  onClick={() => setRegisterEnableValue(!registerDisable)}
                />
              </span>
              <EventFormStatus
                edit={editMode}
                value={status}
                setValue={setStatusValue}
              />
            </li>
            <li className="separator"></li>
            <li className="header">
              <EventFormShare />
            </li>
          </ul>
        </form>
        <div className="event-form-footer">
          <ul>
            <li>
              <StatusLine message={statusMessage} status={statusType} />
            </li>
            <li>
              <Preloader visible={preloaderStatus} />
            </li>
            <li className="separator"></li>
            <li>
              <EventButtonsBar
                show={showSaveModalWindow}
                visible={buttonsStatus}
                link={link}
                submit={submit}
                event={id}
                buttonsBarRef={buttonsBarRef}
                changes={changes}
              />
              <EventRidersList
                visible={ridersListVisible}
                close={() => setRidersListVisible(!ridersListVisible)}
                event={id}
                authorName={author.authorName}
                authorImg={author.authorImg}
                addPeople={addPeople}
              />
            </li>
          </ul>
        </div>
        <ModalWindow
          small={true}
          visible={eventSaveModalVisible}
          close={() => setEventSaveModalVisible(false)}
          title={"Були зроблені зміни"}
          body={
            <div className="center">Ви зробили зміни. Хочете їх зберегти?</div>
          }
          footer={
            <EventChangeModalFooter // changes submit before close window
              refToButtonsBar={buttonsBarRef}
              link={link}
              hide={() => setEventSaveModalVisible(false)}
              submit={submit}
              changes={changes}
            />
          }
        />
        <ModalWindow
          small={true}
          visible={eventCriticalChangesModalVisible}
          close={() => setEventCriticalChangesModalVisible(false)}
          title={"Були зроблені важливі зміни"}
          body={<EventCriticalChangesModalBody text={criticalChangesText} />}
          footer={
            <EventCriticalChangesModalFooter
              refToButtonsBar={buttonsBarRef}
              link={link}
              hide={() => setEventCriticalChangesModalVisible(false)}
              text={criticalChangesText}
              submit={save}
            />
          }
        />
      </div>
    );
  // ==================================================================================
  //                           open form for view (read only):
  // ==================================================================================
  if (editMode === false) {
    return (
      <div className="event-form">
        <EventFormHeader
          link={link}
          editMode={editMode}
          distance={distance}
          event={event}
        />
        <div className="event-view-body">
          <ul>
            <li className="event-view-date-author">
              <div className="date">
                <EventFormDateView date={date} timeStart={timeStart} />
              </div>
              <div className="author">
                <EventFormAuthorView
                  author={author.authorName}
                  img={author.authorImg}
                />
              </div>
            </li>
            <li>
              <EventFormHikeView
                value={duration}
                value2={hikeType}
                isHike={isHike}
              />
            </li>
            <li>
              <EventFormTextareaView value={description} />
            </li>
            <li className="event-props-block">
              <EventProperties
                item={{
                  asphalt: asphalt,
                  pace: pace,
                  relief: relief,
                  difficulty: difficulty,
                }}
              />
            </li>
            <li className="separator"></li>
            <li>Старт:</li>
            <li>
              <EventFormLocationView region={region} ukraine={cities} />
            </li>
            <li className="separator"></li>
            <li>
              <EventFormTextareaView
                value={place}
                title={"Де зустрічаємось:"}
              />
            </li>
            <li>
              <EventFormMap edit={false} position={position} />
            </li>
            <li>
              <EventFormTrack track={track} />
            </li>

            <li>
              <UserContactView
                visible={contactVisible}
                registered={registered}
                type={contactType}
                contact={contactValue}
              />
            </li>
            <li>
              <EventFormTextareaView value={take} title={"Речі з собою:"} />
            </li>

            <li className="header">
              <EventFormShare />
            </li>
            <li className="separator"></li>
            <li className="event-buttons-bar">
              <EventParticipants
                event={id}
                author={false}
                onClick={() => setRidersListVisible(!ridersListVisible)}
                addPeople={addPeople}
                count={ridersCount}
              />
              <EventFormJoin
                user={user}
                event={id}
                registered={registered}
                registerDisable={registerDisable}
                setRegistered={setRegistered}
                joinAction={() => {
                  setEventJoinModalVisible(true);
                }}
                unJoinAction={() => {
                  setEventUnJoinModalVisible(true);
                }}
              />
            </li>
          </ul>
        </div>
        <EventRidersList
          visible={ridersListVisible}
          close={() => setRidersListVisible(!ridersListVisible)}
          event={id}
          authorName={author.authorName}
          authorImg={author.authorImg}
          addPeople={addPeople}
        />
        {/* <EventComments comments={comments} /> */}
        <ModalWindow
          small={true}
          visible={eventJoinModalVisible}
          close={() => setEventJoinModalVisible(false)}
          title={
            "Приєднуюсь" + (duration === 1 ? " до покатеньки" : " до походу")
          }
          body={
            <EventJoinModalBody
              user={user}
              event={id}
              success={() => document.location.reload()}
            />
          }
        />
        <ModalWindow
          small={true}
          visible={eventUnJoinModalVisible}
          close={() => setEventUnJoinModalVisible(false)}
          title={"Відмовитись від участі"}
          footer={
            <EventUnJoinModalFooter
              user={user}
              event={id}
              success={() => document.location.reload()}
            />
          }
        />
      </div>
    );
  } else return null;
};

// ======================== Main page component =

const Events = (props) => {
  // ride & hike events component
  const [login, setLogin] = useState(false); // is user login
  const [loginChecked, setLoginChecked] = useState(false); // is login checked

  const [events, setEvents] = useState([]); // events list
  const [currentUser, setCurrentUser] = useState(0); // current user id
  const [confirmed, setConfirmed] = useState(false); // current user id

  const checkLoginAction = (data) => {
    setLogin(data);
    setLoginChecked(true);
  };

  useEffect(() => {
    checkLogin(checkLoginAction);
  }, []);

  useEffect((type = props.type) => {
    const load = async () => {
      //load events list
      let user; // user data
      let session = localStorage.getItem("session");
      let likes = [];
      if (session) {
        try {
          // load user data
          let data = new FormData();
          data.append("session", session);
          user = await axios.post(API + "get_user_data", data);
          setCurrentUser(user.data.id);
          if (user.data.login) setConfirmed(+user.data.confirmed);
        } catch (err) {
          console.log("Error load user data: ", err);
        }
      } else {
        user = { data: { id: null, confirmed: false } };
      }

      let registered = []; // list of events where user joined
      if (user) {
        try {
          // load list of events where user joined
          let data = new FormData();
          data.append("user", user.data.id);
          let getList = await axios.post(API + "get_registered", data);
          if (getList.data)
            registered = getList.data.map((i) => {
              // create registered events id list
              return +i.event_id;
            });
        } catch (err) {
          console.log("Error load register list: ", err);
        }
      } else registered = null;

      // get events liked by current user:
      try {
        // load current user liked events
        let data = new FormData();
        data.append("user_id", user.data.id);
        let res = await axios.post(API + "get_likes", data);
        if (res.data) {
          likes = res.data;
        }
      } catch (err) {
        console.log("Likes load error: ", err);
      }

      try {
        // load events list & set events state
        let data = new FormData();
        data.append("type", type);
        let res = await axios.post(API + "get_events", data);
        if (res.data) {
          let eventsArr = [];
          res.data.forEach((i, n) => {
            i.isAuthor = +i.author_id === +user.data.id ? true : false;
            i.joined =
              registered &&
              registered.some((item) => {
                return +item === +i.id;
              })
                ? true
                : false;
            i.like = likes.find((item) => item.event_id == i.id) ? true : false;
            eventsArr.push(i);
          });
          setEvents(eventsArr);
        }
      } catch (err) {
        console.log("Events list load error: ", err);
      }
    };

    load();
  }, []);

  useEffect(() => {
    const ref = localStorage.getItem("ref");
    let el;
    if (ref) el = document.getElementById(ref);
    if (el) {
      el.scrollIntoView(false);
    }
  });

  if (!events) return null; // exit if not fetched data

  let eventsItems = [];
  for (let i of events) {
    const region = getRegion(i.city_id, cities);
    eventsItems.push(
      <Event
        item={i}
        key={i.id}
        cityID={i.city_id}
        city={region.region}
        currentUser={currentUser}
        login={login}
      />
    );
  }
  return (
    <div className="events-page">
      <Header
        type={props.type}
        user={props.user}
        login={login}
        confirmed={confirmed}
      />
      <div className="events-list">{eventsItems}</div>
      <FooterWarning
        login={login}
        confirmed={confirmed}
        loginChecked={loginChecked}
      />
    </div>
  );
};

const Header = (props) => {
  const [nawWindowVisible, setNavWindowVisible] = useState(false);
  const showHideNawWindow = () => {
    // show or hide menu window
    setNavWindowVisible(!nawWindowVisible);
  };
  return (
    <div>
      <div className="events-header">
        <Logo click={true} small={true} />
        <div className="menu-block">
          <div className="header-item">
            <EventCreateButton type={props.type} login={props.login} />
          </div>
          <div className="header-item">
            <NawWindowButton
              visible={!nawWindowVisible}
              show={showHideNawWindow}
            />
          </div>
          <div className="header-item">
            <HeaderUser userName={props.user.name} userImg={props.user.img} />
          </div>
        </div>
        <NawWindow
          type={props.type}
          login={props.login}
          visible={nawWindowVisible}
          show={showHideNawWindow}
        />
      </div>
    </div>
  );
};
//-----------------------------------------------
const FooterWarning = (props) => {
  if (!props.loginChecked) return null;
  if (props.login && props.confirmed) return null;
  return !props.login ? (
    <h2 className="footer-warning">
      Щоб створювати походи/покатеньки або брати у них участь, Вам необхідно{" "}
      <a href="/login">
        <u>увійти або зареєструватись</u>
      </a>
    </h2>
  ) : (
    <h2 className="footer-warning">
      Вам необхідно
      <a href="/profile"> заповнити профіль користувача </a>
    </h2>
  );
};
//-----------------------------------------------
const HeaderUser = (props) => {
  // props:
  // user
  // img
  const imgTag = props.userImg ? (
    <img src={API + UPLOADS + props.userImg} alt="Фото профілю n4r" />
  ) : (
    <img src={img_noAvatar} alt="Фото профілю n4r" />
  );
  return (
    <div className="header-user-component">
      <Link to="/profile">
        <div className="img">{imgTag}</div>
      </Link>
    </div>
  );
};

//-----------------------------------------------
const NawWindowButton = (props) => {
  if (!props.visible) return <div className="invisible"></div>;
  return (
    <div
      onClick={() => {
        props.show();
      }}
    >
      <span className="icon">
        <BsList />
      </span>
    </div>
  );
};
//-----------------------------------------------
const EventCreateButton = (props) => {
  // component for event create
  const css = props.login ? null : "disabled-element";
  const icon = (
    <span className="icon">
      <BsFillPlusCircleFill />
    </span>
  );
  const onClick = props.login ? null : (e) => e.preventDefault(); // disable link if not login
  let item1 =
    props.type === "hike" ? (
      <Link to={"/event/-2"} onClick={onClick}>
        {icon}
        <span className="title">Створити похід</span>
      </Link>
    ) : (
      <Link to={"/event/-1"} onClick={onClick}>
        {icon}
        <span className="title">Створити покатеньку</span>
      </Link>
    );
  return <div className={css}>{item1}</div>;
};
//-----------------------------------------------
const NawWindow = (props) => {
  //eventType: 'hike' || 'ride' || 'drive'
  //visible: boolean
  const ModalPad = (props) => {
    return <div className="modal-pad" onClick={props.onClick}></div>;
  };
  if (!props.visible) return <div className="invisible"></div>;

  let item2 = props.type === "hike" ? "Покатеньки" : "Походи";
  let href = props.type === "ride" ? "/hike" : "/ride";
  let invisible = props.login ? "item invisible" : "item";
  // const cssDisable = props.login ? null : "disabled-element";
  return (
    <div>
      <ModalPad onClick={() => props.show()} />
      <div className="nav-window">
        <div className="nav-window-header">
          <WindowCloseBtn onClick={props.show} />
        </div>
        <ul>
          <li className="item">
            <EventCreateButton type={props.type} login={props.login} />
          </li>
          <li>
            <a className="item" href={href}>
              <span className="icon">
                <BsArrowLeft />
              </span>
              {item2}
            </a>
          </li>
          {/* <li className="item">
            <span className="icon">
              <BsFunnel />
            </span>
            Фільтри
          </li> */}
          {/* <li className="item">
            <span className="icon">
              <BsFillPeopleFill />
            </span>
            Користувачі
          </li> */}
          {/* <li className="item">
            <span className="icon">
              <HiUserGroup />
            </span>
            Групи
          </li> */}
          {/* <li
            className={"item " + cssDisable}
            onClick={props.login ? null : (e) => e.preventDefault()}
          >
            <span className="icon">
              <BsFileEarmarkPlus />
            </span>
            Створити групу
          </li> */}
          <li>
            <a className="item" href="/">
              <span className="icon">
                <BsHouseDoor />
              </span>
              На головну
            </a>
          </li>
          <li className={invisible}>
            <a className="item" href="/login">
              <span className="icon">
                <BsBoxArrowInRight />
              </span>
              Увійти
            </a>
          </li>
          <li className={invisible}>
            <a className="item" href="/register">
              <span className="icon">
                <BsPersonPlusFill />
              </span>
              Зареєструватись
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { Events, EventEdit };
